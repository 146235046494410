import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextInput,
  useSnackbar,
} from '@c2fo/liquidity';
import useUpdateCompanyProfile from '@/pages/AccountSettings/data/useUpdateCompanyProfile';
import useFormDropdownOptions from '@/pages/AccountSettings/utils/useFormDropdownOptions';

interface CompanyProfileFormInputs {
  arDepartmentEmailAddress: string;
  fiscalYearEnd: string;
  industry: string;
}

interface CompanyProfileFormProps {
  defaultValues: CompanyProfileFormInputs;
  onClose: () => void;
}

const CompanyProfileForm = ({ defaultValues, onClose }: CompanyProfileFormProps) => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<CompanyProfileFormInputs>({ defaultValues });
  const { mutateAsync, isLoading } = useUpdateCompanyProfile();
  const { industryOptions, monthOptions } = useFormDropdownOptions();

  const onSubmit: SubmitHandler<CompanyProfileFormInputs> = async (data) => {
    const args = {
      arDepartmentEmailAddress: data.arDepartmentEmailAddress,
      fiscalYearEnd: data.fiscalYearEnd,
      industry: data.industry,
    };

    await mutateAsync(args, {
      onError: () => {
        showSnackbar({ message: t('accountSettings.error') });
      },
      onSuccess: () => {
        showSnackbar({ message: t('accountSettings.success') });
        onClose();
      },
    });
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="industry" required>
            {t('accountSettings.industry')}
          </Label>
          <Controller
            control={control}
            name="industry"
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger aria-label={t('accountSettings.industry')} hasError={!!errors.industry}>
                  <SelectValue placeholder={t('accountSettings.industry')} />
                </SelectTrigger>
                <SelectContent>
                  {industryOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="fiscalYearEnd" required>
            {t('accountSettings.fiscalYearEnd')}
          </Label>
          <Controller
            control={control}
            name="fiscalYearEnd"
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger aria-label={t('accountSettings.fiscalYearEnd')} hasError={!!errors.fiscalYearEnd}>
                  <SelectValue placeholder={t('accountSettings.fiscalYearEnd')} />
                </SelectTrigger>
                <SelectContent>
                  {monthOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="flex flex-col md:mr-6 md:flex-row">
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="arDepartmentEmailAddress">{t('accountSettings.arDepartmentEmailAddress')}</Label>
          <TextInput
            id="arDepartmentEmailAddress"
            className="w-full"
            hasError={!!errors.arDepartmentEmailAddress}
            placeholder={t('accountSettings.arDepartmentEmailAddress')}
            {...register('arDepartmentEmailAddress')}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Button disabled={isLoading} onClick={onClose} variant="secondary">
          {t('core.cancel')}
        </Button>
        <Button loading={isLoading} type="submit">
          {t('core.save')}
        </Button>
      </div>
    </form>
  );
};

export default CompanyProfileForm;
