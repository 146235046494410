import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, cn, HelperText, SearchInput } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';
import emptystatements from '@/assets/emptystatementsgrey.svg?url';
import NoDataState from '@/components/NoDataState';
import {
  Table,
  TableBody,
  TableCell,
  TableDisplay,
  TableHead,
  TableHeader,
  TableRow,
  TableSortArrow,
  TableSortDirection,
} from '@/components/Table';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import sortTakerMarketTable, { Sort, SortKey } from '@/features/takerMarketTable/utils/sortTakerMarketTable';
import getMarketType from '@/utils/getMarketType';
import getTakerMarketDivisionTitle from '@/utils/getTakerMarketDivisionTitle';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useLocaleFormat from '@/utils/useLocaleFormat';
import getRecurringRuleForCategory from '../utils/getRecurringRuleForCategory';
import useGetRecurringRulesForTakerMarkets from '../utils/useGetRecurringRulesForTakerMarkets';
import useRecurringRuleFormContext from '../utils/useRecurringRuleFormContext';
import useRuleFormContent from '../utils/useRuleFormContent';
import { RecurringRuleFormError } from '../utils/useValidateRuleForm';
import Pagination, { getStartEnd } from './Pagination';
import RuleChips from './RuleChips';

interface DivisionSelectTableProps {
  onSelect: (takerMarkets: TakerMarket[]) => void;
  selectedTakerMarkets: TakerMarket[];
  takerMarkets: TakerMarket[];
  hasError?: RecurringRuleFormError;
  type?: TakerMarketGroupType;
}

const takerMarketGroupTypeTranslationMap = {
  NAME_YOUR_RATE: 'core.nameYourRateDivisionsSelected',
  BENCHMARK: 'core.variableRateDivisionsSelected',
  PREFERRED: 'core.preferredRateDivisionsSelected',
  FIXED: 'core.fixedRateDivisionsSelected',
  INACTIVE: 'core.inactiveDivisionsSelected',
};

const DivisionSelectTable = ({
  hasError,
  onSelect,
  selectedTakerMarkets,
  takerMarkets = [],
  type,
}: DivisionSelectTableProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const { getValues } = useRecurringRuleFormContext();
  const category = getValues('category');
  const { getRuleFormContent } = useRuleFormContent();
  const { existingTitle } = getRuleFormContent(category);
  const { asCurrency, asNumber } = useLocaleFormat();
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<Sort>({ key: 'eligibleInvoiceAmount', direction: 'desc' });
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const sortedTakerMarkets = sortTakerMarketTable(takerMarkets, sort);
  const [filteredTakerMarkets, setFilteredTakerMarkets] = useState<TakerMarket[]>(sortedTakerMarkets);

  const filteredCount = filteredTakerMarkets.length;
  const selectedCount = selectedTakerMarkets.length;
  const totalCount = takerMarkets.length;
  const { start, end } = getStartEnd({ limit, page, totalCount });
  const selectedTakerMarketsText = type
    ? t(takerMarketGroupTypeTranslationMap[type], {
        selectedCount: asNumber({ value: selectedCount }),
        totalCount: asNumber({ value: totalCount }),
      })
    : t('core.divisionsSelectedCount', {
        selectedCount: asNumber({ value: selectedCount }),
        totalCount: asNumber({ value: totalCount }),
      });
  const allSelected =
    selectedCount > 0 &&
    filteredCount > 0 &&
    filteredTakerMarkets.every((takerMarket) =>
      selectedTakerMarkets.find(
        (selectedTakerMarket) =>
          selectedTakerMarket.marketUuid === takerMarket.marketUuid &&
          selectedTakerMarket.takerDivisionId === takerMarket.takerDivisionId
      )
    );

  useEffect(() => {
    setFilteredTakerMarkets(
      sortedTakerMarkets.filter((takerMarket) => {
        return (
          getTakerMarketName(takerMarket).toLowerCase().includes(search.toLowerCase()) ||
          getTakerMarketDivisionTitle(takerMarket)?.title.toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  }, [search, sort, sortedTakerMarkets]);

  const onPageSizeChange = (limit: number) => {
    setLimit(limit);
    setPage(1);
  };

  const onSort = (accessorKey: SortKey) => {
    let direction: TableSortDirection = accessorKey === 'makerOrganizationName' ? 'asc' : 'desc';

    if (sort?.key === accessorKey) {
      direction = sort.direction === 'desc' ? 'asc' : 'desc';
    }

    setSort({ key: accessorKey, direction });
  };

  const onSearch = (value: string) => {
    setSearch(value);
    setPage(1);
  };

  // single taker market row is clicked
  const onSelectOne = (takerMarket: TakerMarket) => {
    const exists = selectedTakerMarkets.find(
      (selectedTakerMarket) =>
        selectedTakerMarket.marketUuid === takerMarket.marketUuid &&
        selectedTakerMarket.takerDivisionId === takerMarket.takerDivisionId
    );

    // if already selected, filter from selectedTakerMarkets
    // if not, add to selectedTakerMarkets
    const updated = exists
      ? selectedTakerMarkets.filter(
          (selectedTakerMarket) =>
            selectedTakerMarket.marketUuid !== takerMarket.marketUuid ||
            selectedTakerMarket.takerDivisionId !== takerMarket.takerDivisionId
        )
      : [...selectedTakerMarkets, takerMarket];

    return onSelect(updated);
  };

  // select all checkbox is clicked
  const onSelectAll = (checked: boolean) => {
    // merged selected and currently filtered taker markets
    const allSelected = [...filteredTakerMarkets, ...selectedTakerMarkets];

    if (checked) {
      // remove any duplicates
      const updated = allSelected.filter(
        (takerMarket, index, self) => self.findIndex((s) => s.id === takerMarket.id) === index
      );

      return onSelect(updated);
    } else {
      // get a list of those already selected
      const alreadySelectedTakerMarkets = allSelected.filter(
        (takerMarket, index, self) => self.findIndex((s) => s.id === takerMarket.id) !== index
      );
      // filter out already selected
      const updated = allSelected.filter(
        (takerMarket) =>
          !alreadySelectedTakerMarkets.find(
            (alreadySelectedTakerMarket) =>
              alreadySelectedTakerMarket.marketUuid === takerMarket.marketUuid &&
              alreadySelectedTakerMarket.takerDivisionId === takerMarket.takerDivisionId
          )
      );

      return onSelect(updated);
    }
  };

  return (
    <div>
      <div className="mb-1 font-medium">{t('core.divisions')}</div>
      <div
        className={cn(
          'flex h-20 items-center justify-between rounded-t border border-b-0 border-stroke bg-gray-50 px-4',
          { 'border-red-600': hasError }
        )}
      >
        <div>
          <div>{selectedTakerMarketsText}</div>
          {search !== '' && (
            <div className="font-bold">
              {filteredCount === 1
                ? t('core.divisionsReturned', { value: asNumber({ value: filteredCount }) })
                : t('core.divisionsReturnedPlural', { value: asNumber({ value: filteredCount }) })}
            </div>
          )}
        </div>
        <SearchInput
          aria-label={t('core.search')}
          name="Search"
          onChange={(e) => onSearch(e.target.value)}
          onClear={() => onSearch('')}
          placeholder={t('core.search')}
          size="sm"
          value={search}
        />
      </div>
      <div className={cn('w-full overflow-auto border border-stroke', { 'border-x-red-600': hasError })}>
        <Table>
          <TableHeader className="border-b border-stroke bg-gray-50">
            <TableRow>
              <TableHead className="w-10" condensed>
                <Checkbox
                  checked={allSelected}
                  disabled={filteredCount === 0}
                  color="primary"
                  onChange={(e) => onSelectAll(e.currentTarget.checked)}
                />
              </TableHead>
              <TableHead className="max-w-48 normal-case" onClick={() => onSort('makerOrganizationName')}>
                <TableSortArrow accessorKey="makerOrganizationName" sort={sort}>
                  {t('core.division')}
                </TableSortArrow>
              </TableHead>
              <TableHead className="pl-8 pr-10 normal-case" onClick={() => onSort('eligibleInvoiceAmount')}>
                <TableSortArrow accessorKey="eligibleInvoiceAmount" sort={sort} textRight>
                  {t('taker.dictionary.availableAR.label')}
                </TableSortArrow>
              </TableHead>
              <TableHead className="normal-case">{t(existingTitle)}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredTakerMarkets.length === 0 ? (
              <TableDisplay colSpan={4}>
                <NoDataState
                  className="py-8"
                  icon={<img src={emptystatements} alt="emptystatements" className="h-28 w-28" />}
                  title={t('taker.noMatchingDivisions')}
                  message={t('taker.noMatchingDivisionsSearchOnly')}
                />
              </TableDisplay>
            ) : (
              filteredTakerMarkets.slice(start - 1, end).map((takerMarket) => {
                const recurringRule = getRecurringRulesForTakerMarkets([takerMarket])[0];
                const recurringRuleForCategory = getRecurringRuleForCategory(recurringRule, category);
                const isPreferred = getMarketType(takerMarket) === 'PREFERRED';

                return (
                  <TableRow key={takerMarket.id}>
                    <TableCell condensed>
                      <Checkbox
                        checked={selectedTakerMarkets.some(
                          (selectedTakerMarket) =>
                            selectedTakerMarket.marketUuid === takerMarket.marketUuid &&
                            selectedTakerMarket.takerDivisionId === takerMarket.takerDivisionId
                        )}
                        color="primary"
                        onChange={() => onSelectOne(takerMarket)}
                        disabled={isPreferred}
                      />
                    </TableCell>
                    <TableCell className="w-48">
                      <div className="w-48 truncate">
                        <div className="truncate font-medium" title={getTakerMarketName(takerMarket)}>
                          {getTakerMarketName(takerMarket)}
                        </div>
                        <div className="text-sm text-text-secondary">{takerMarket.currency}</div>
                        <div
                          className="items-center truncate text-sm text-text-secondary"
                          title={getTakerMarketDivisionTitle(takerMarket)?.title}
                        >
                          {getTakerMarketDivisionTitle(takerMarket)?.content}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="w-40 pl-8 pr-10 text-right">
                      {asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency)}
                    </TableCell>
                    <TableCell>
                      {recurringRule && recurringRuleForCategory && (
                        <RuleChips recurringRule={recurringRuleForCategory} readOnly />
                      )}
                      {isPreferred && (
                        <div className="flex gap-1 pt-2">
                          <InfoCircleIcon className="h-4 w-4 shrink-0 pt-1" fill={colors.gray[600]} />
                          <span className="text-wrap text-sm italic text-text-secondary">
                            {t('recurringRules.preferredOfferAlert')}
                          </span>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <div
        className={cn('flex justify-center rounded-b border border-t-0 border-stroke bg-gray-50 p-4', {
          'border-red-600': hasError,
        })}
      >
        <Pagination
          limit={limit}
          onPageChange={setPage}
          onPageSizeChange={onPageSizeChange}
          page={page}
          totalCount={filteredCount}
        />
      </div>
      {hasError && (
        <div className="mt-1">
          <HelperText variant="error">{hasError.message}</HelperText>
        </div>
      )}
    </div>
  );
};

export default DivisionSelectTable;
