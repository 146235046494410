import Divider from '@/components/Divider';

interface HeaderProps {
  title: string;
  actionSlot?: React.ReactNode;
  subTitle?: string | null;
}

const Header = ({ actionSlot, subTitle, title }: HeaderProps) => {
  return (
    <>
      <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
        <div>
          <h1 className="m-0 font-serif text-4xl">{title}</h1>
          {subTitle && <div className="mt-2 text-text-secondary">{subTitle}</div>}
        </div>
        {actionSlot && <div>{actionSlot}</div>}
      </div>
      <div className="my-8">
        <Divider />
      </div>
    </>
  );
};

export default Header;
