import { useQueryClient } from '@tanstack/react-query';
import QueryBoundaries from '@/components/QueryBoundaries';
import { useReporting } from '@/reporting';
import useDataDog from './DataDog';
import { mutationKeyTrack } from './Reporting';

/**
 * fires a track any time a useMutation error occurs
 */
const MutationErrorTrackerComponent = () => {
  const { track } = useReporting();
  const { addError } = useDataDog();
  const queryClient = useQueryClient();

  queryClient.setMutationDefaults([], {
    onError(error) {
      /**
       * If the mutationKey is 'track', we don't want to track the error again and cause an infinite loop.
       * Instead, we'll add the error to DataDog directly and avoid another mutation that could call this again.
       */
      if (this.mutationKey === mutationKeyTrack) {
        return addError(error, {
          mutationKey: this?.mutationKey ?? 'undefinedMutationKey',
          errorMessage: error instanceof Error ? error.message : 'Unknown mutation error',
        });
      }

      track('mutation::error', {
        mutationKey: this?.mutationKey ?? 'undefinedMutationKey',
        errorMessage: error instanceof Error ? error.message : 'Unknown mutation error',
      });
    },
  });

  return null;
};

const MutationErrorTracker = () => (
  <QueryBoundaries>
    <MutationErrorTrackerComponent />
  </QueryBoundaries>
);

export default MutationErrorTracker;
