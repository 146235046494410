import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { InfoCircleIcon } from '@c2fo/liquidity/icons';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import useMarketClearTimes, { MarketClearTimeData } from '@/data/useMarketClearTimes';
import { TakerMarket } from '@/data/useTakerMarkets';

const pad2Digits = (number: number) => `${number}`.padStart(2, '0');

const calcRelativeTimesUntilNextClear = (nextClearTime: string | null | undefined) => {
  const defaultValue = { days: 0, hours: 0, minutes: 0, totalSecondsFromNow: 0, invalidDate: true };

  // nextClearTime must be a non empty string
  if (!nextClearTime) {
    return defaultValue;
  }

  const parsedNextClearTime = Date.parse(nextClearTime);

  // Date must parse successfully
  if (isNaN(parsedNextClearTime)) {
    return defaultValue;
  }

  const totalSecondsFromNow = Date.parse(nextClearTime) - Date.now();

  // When the date is in the past, return defaults instead of negative values
  if (totalSecondsFromNow < 0) {
    return { ...defaultValue, invalidDate: false };
  }

  const days = Math.floor(totalSecondsFromNow / (1000 * 60 * 60 * 24));
  const hours = Math.floor(totalSecondsFromNow / (1000 * 60 * 60)) % 24;
  const minutes = Math.floor((totalSecondsFromNow / 1000 / 60) % 60);

  return { days, hours, minutes, totalSecondsFromNow, invalidDate: false };
};

const UnknownNextClearTime = () => {
  const { t } = useTranslation();
  return <span>{t('core.na')}</span>;
};

const NextMarketClearTimeCountdownCore = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  const { t } = useTranslation();
  const { data: nextClearTimeData } = useMarketClearTimes(
    useCallback(
      (marketClearTimeDataArr: MarketClearTimeData[]) => {
        return marketClearTimeDataArr.find(
          (marketClearTimeData) =>
            marketClearTimeData.marketUuid === takerMarket.marketUuid &&
            marketClearTimeData.supplierDivisionUuid === takerMarket.takerDivisionUuid
        );
      },
      [takerMarket.marketUuid, takerMarket.takerDivisionUuid]
    )
  );

  const {
    days: defaultDays,
    hours: defaultHours,
    minutes: defaultMinutes,
  } = calcRelativeTimesUntilNextClear(nextClearTimeData?.marketNextClearTime);

  const [days, setDays] = useState(defaultDays);
  const [hours, setHours] = useState(defaultHours);
  const [minutes, setMinutes] = useState(defaultMinutes);

  // Next clear time can be null when a clear schedule has not been setup for a market
  const [marketProcessingStatus, setMarketProcessingStatus] = useState<
    'PROCESSING' | 'PROCESSING_SOON' | 'READY' | 'UNKNOWN'
  >(nextClearTimeData?.marketNextClearTime ? 'READY' : 'UNKNOWN');

  const getCountdownTime = useCallback(() => {
    const {
      days: updatedDays,
      hours: updatedHours,
      minutes: updatedMinutes,
      totalSecondsFromNow,
      invalidDate,
    } = calcRelativeTimesUntilNextClear(nextClearTimeData?.marketNextClearTime);

    if (invalidDate) {
      return setMarketProcessingStatus('UNKNOWN');
    }

    setDays(updatedDays);
    setHours(updatedHours);
    setMinutes(updatedMinutes);

    if (totalSecondsFromNow <= 0) {
      setMarketProcessingStatus('PROCESSING');
      // We don't show seconds, so anything less than 11 will show as "10m", which is what we want
    } else if (totalSecondsFromNow < 11 * 60 * 1000) {
      setMarketProcessingStatus('PROCESSING_SOON');
    } else {
      setMarketProcessingStatus('READY');
    }
  }, [nextClearTimeData]);

  useEffect(() => {
    // Run immediately on mount
    getCountdownTime();

    // Update on a regular interval
    const interval = setInterval(() => getCountdownTime(), 1000);

    // Clear interval on unmount
    return () => clearInterval(interval);
  }, [getCountdownTime, nextClearTimeData]);

  const countdownString = `${days > 0 ? `${pad2Digits(days)}d ` : ''}${pad2Digits(hours)}h ${pad2Digits(minutes)}m`;

  switch (marketProcessingStatus) {
    case 'PROCESSING':
      return (
        <span className="inline-flex items-center gap-1 truncate text-red-600">
          {countdownString} - <span className="truncate">{t('core.marketIsProcessing')}</span>
          <Popover>
            <Popover.Trigger className="inline-flex items-center gap-1 text-red-600">
              <InfoCircleIcon fill={colors.gray[800]} />
            </Popover.Trigger>
            <Popover.Content arrow className="max-w-[300px]">
              {t('core.marketIsProcessingPopover')}
            </Popover.Content>
          </Popover>
        </span>
      );
    case 'PROCESSING_SOON':
      return (
        <span className="inline-flex items-center gap-1 truncate text-amber-800">
          {countdownString} - <span className="truncate">{t('core.marketIsProcessingSoon')}</span>
          <Popover>
            <Popover.Trigger className="inline-flex items-center gap-1 text-red-600">
              <InfoCircleIcon fill={colors.gray[800]} />
            </Popover.Trigger>
            <Popover.Content arrow className="max-w-[300px]">
              {t('core.marketIsProcessingSoonPopover', { minutes })}
            </Popover.Content>
          </Popover>
        </span>
      );
    case 'READY':
      return <span>{countdownString}</span>;
    case 'UNKNOWN':
    default:
      return <UnknownNextClearTime />;
  }
};

const NextMarketClearTimeCountdown = ({ takerMarket }: { takerMarket: TakerMarket }) => {
  return (
    <QueryBoundaries
      LoadingComponent={() => <Skeleton className="inline-block h-3 w-14 bg-gray-100" shadow={false} />}
      ErrorComponent={() => <UnknownNextClearTime />}
    >
      <NextMarketClearTimeCountdownCore takerMarket={takerMarket} />
    </QueryBoundaries>
  );
};

export default NextMarketClearTimeCountdown;
