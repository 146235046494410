import { useTranslation } from 'react-i18next';
import { Toggle, Tooltip, TooltipContentProps } from '@c2fo/liquidity';

const PreferredParticipationToggle = ({ variant = 'light' }: Pick<TooltipContentProps, 'variant'>) => {
  const { t } = useTranslation();

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <span className="inline-block">
          <Toggle accessibilityLabel={t('core.toggleParticipation')} checked disabled locked size="lg" />
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content arrow side="bottom" variant={variant}>
        {t('takerMarketTabs.preferredRate.switchTooltip')}
      </Tooltip.Content>
    </Tooltip>
  );
};

export default PreferredParticipationToggle;
