import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { PersonalInfoInput, UserInput } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';
import { useToken } from '@/utils/token';

interface UpdateCompanyProfileArgs {
  arDepartmentEmailAddress: PersonalInfoInput['arDepartmentEmailAddress'];
  fiscalYearEnd: PersonalInfoInput['fiscalYearEnd'];
  industry: PersonalInfoInput['industry'];
}

const UPDATE_COMPANY_PROFILE = graphql(`
  mutation UpdateCompanyProfile($users: [UserInput!]) {
    users(users: $users) {
      success
    }
  }
`);

const useUpdateCompanyProfile = () => {
  const queryClient = useQueryClient();
  const { tokenContent } = useToken();
  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? '';

  return useMutation({
    mutationKey: ['updateCompanyProfile'],
    mutationFn: (args: UpdateCompanyProfileArgs) => {
      const input = {
        users: [
          {
            id: authServiceUuid,
            personalInfo: {
              arDepartmentEmailAddress: args.arDepartmentEmailAddress,
              fiscalYearEnd: args.fiscalYearEnd,
              industry: args.industry,
            } satisfies PersonalInfoInput,
          } satisfies UserInput,
        ],
      };

      return gqlClient.request(UPDATE_COMPANY_PROFILE, input);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['user-details']);
    },
  });
};

export default useUpdateCompanyProfile;
