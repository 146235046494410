import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

const useResetPassword = () => {
  return useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: ({ emailAddress }: { emailAddress: string }) =>
      apiClient.post('api/c2fo/user-source/forgot-password', {
        json: {
          emailAddress,
        },
      }),
  });
};

export default useResetPassword;
