import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation } from 'react-router-dom';
import HomepageLayout from '@/components/HomepageLayout';
import InvoiceLayout from '@/components/InvoiceLayout';
import MarketLayout from '@/components/MarketLayout';
import NavigateWithRedirect from '@/components/NavigateWithRedirect';
import DraftOffer from '@/features/draftOffer/DraftOffer';
import FixedRateMarketTable from '@/features/takerMarketTable/FixedRateMarketTable';
import InactiveMarketTable from '@/features/takerMarketTable/InactiveMarketTable';
import NameYourRateMarketTable from '@/features/takerMarketTable/NameYourRateMarketTable';
import PreferredRateMarketTable from '@/features/takerMarketTable/PreferredRateMarketTable';
import VariableRateMarketTable from '@/features/takerMarketTable/VariableRateMarketTable';
import TakerMarketTabs from '@/features/takerMarketTabs/TakerMarketTabs';
import accountSettingsRouteConfig from '@/pages/AccountSettings/Router';
import { Auth, AuthImpersonation, AuthMagicLogin } from '@/pages/Auth';
import AwardedInvoices from '@/pages/AwardedInvoices';
import ConsolidatedHistory from '@/pages/ConsolidatedHistory';
import EligibleInvoices from '@/pages/EligibleInvoices';
import IneligibleInvoices from '@/pages/IneligibleInvoices';
import InvoiceAdjustments from '@/pages/InvoiceAdjustments';
import MarketHistory from '@/pages/MarketHistory';
import MarketSearch from '@/pages/MarketSearch';
import RecurringRulesManager from '@/pages/RecurringRulesManager';
import SomethingsWrong from '@/pages/SomethingsWrong';
import reauthenticate from '@/utils/reauthenticate';
import getToken from '@/utils/token';
import useSmbUser from '@/utils/useSmbUser';
import App from './App';
import ConsolidatedHistoryLayout from './components/ConsolidatedHistoryLayout';
import { FeatureFlagEnabledRoute, FeatureFlagProtectedRoute } from './components/FeatureFlagProtectedRouting';
import MarketHistoryLayout from './components/MarketHistoryLayout';
import { PendingConsolidatedHistory } from './features/history/PendingConsolidatedHistory';
import { PendingMarketHistory } from './features/history/PendingMarketHistory';
import findYourCustomersRouteConfig from './pages/FindYourCustomer/Router';

const basename = import.meta.env.VITE_BASE_PATH;

const PassthroughElement = () => {
  const { pathname } = useLocation();
  const token = getToken();
  const isSmbUser = useSmbUser();
  const smbPermittedPath = 'supplier/history';
  const smbUrl = `${window.location.origin}/early-pay`;

  if (pathname.includes('/auth')) {
    return <Outlet />;
  }

  if (token) {
    // If user has NSE enabled and is not an internal user, redirect to SMB
    if (isSmbUser) {
      return pathname.includes(smbPermittedPath) ? <Outlet /> : <>{window.location.replace(smbUrl)}</>;
    }

    // ensure "supplier" is treated as base URL
    // trailing "/" is important to ensure we only match nested routes
    if (pathname.includes('supplier/')) {
      return <Outlet />;
    }

    return <Navigate to="/supplier/markets" />;
  }

  reauthenticate();
  return null;
};

const Router = () => {
  const browserRouter = createBrowserRouter(
    [
      {
        children: [
          {
            path: '/',
            element: <PassthroughElement />,
            children: [
              {
                path: 'supplier',
                element: <PassthroughElement />,
                children: [
                  {
                    element: <App />,
                    children: [
                      {
                        path: 'markets',
                        element: <HomepageLayout />,
                        children: [
                          {
                            element: <TakerMarketTabs />,
                            children: [
                              {
                                index: true,
                                element: <NameYourRateMarketTable />,
                              },
                              {
                                path: 'inactive',
                                element: <InactiveMarketTable />,
                              },
                              {
                                path: 'fixed',
                                element: <FixedRateMarketTable />,
                              },
                              {
                                path: 'preferred',
                                element: <PreferredRateMarketTable />,
                              },
                              {
                                path: 'variable',
                                element: <VariableRateMarketTable />,
                              },
                              {
                                // catch all route for /markets/:route that does not exist
                                path: '*',
                                element: <NavigateWithRedirect to="/markets" />,
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'markets/search',
                        element: (
                          <FeatureFlagEnabledRoute
                            featureFlag="enterprise-ui_enableSeaFindYourCustomer"
                            redirectTo="/supplier/find-your-customers"
                          >
                            <MarketSearch />
                          </FeatureFlagEnabledRoute>
                        ),
                      },
                      {
                        path: 'markets/:marketId/division/:takerId',
                        element: <MarketLayout />,
                        children: [
                          {
                            // index route for /markets/:marketId/division/:takerId
                            index: true,
                            element: <NavigateWithRedirect to="invoices/eligible" />,
                          },
                          {
                            path: 'invoices',
                            element: <InvoiceLayout />,
                            children: [
                              {
                                // index route for /markets/:marketId/division/:takerId/invoices
                                index: true,
                                element: <NavigateWithRedirect to="eligible" />,
                              },
                              {
                                path: 'adjustments',
                                element: <InvoiceAdjustments />,
                              },
                              {
                                path: 'awarded',
                                element: <AwardedInvoices />,
                              },
                              {
                                path: 'eligible',
                                element: <EligibleInvoices />,
                              },
                              {
                                path: 'ineligible',
                                element: <IneligibleInvoices />,
                              },
                              {
                                // catch all route for /markets/:marketId/division/:takerId/invoices/:route that does not exist
                                path: '*',
                                element: <NavigateWithRedirect to="eligible" />,
                              },
                            ],
                          },
                          {
                            path: 'history',
                            // ff on, tab format. ff off, just Outlet with MarketLayout context passed.
                            element: <MarketHistoryLayout />,
                            children: [
                              {
                                index: true,
                                element: (
                                  <FeatureFlagEnabledRoute
                                    featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                                    redirectTo="approved"
                                  >
                                    <MarketHistory />
                                  </FeatureFlagEnabledRoute>
                                ),
                              },
                              {
                                path: 'approved',
                                // ff on, Approved. ff off, Division page.
                                element: (
                                  <FeatureFlagProtectedRoute
                                    featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                                    redirectTo=".."
                                  >
                                    <MarketHistory />
                                  </FeatureFlagProtectedRoute>
                                ),
                              },
                              {
                                path: 'pending',
                                // ff on, Pending. ff off, Division page.
                                element: (
                                  <FeatureFlagProtectedRoute
                                    featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                                    redirectTo=".."
                                  >
                                    <PendingMarketHistory />
                                  </FeatureFlagProtectedRoute>
                                ),
                              },
                            ],
                          },
                          {
                            // catch all route for /markets/:marketId/division/:takerId/:route that does not exist
                            path: '*',
                            element: <NavigateWithRedirect to="invoices/eligible" />,
                          },
                        ],
                      },
                      {
                        path: 'history',
                        element: <ConsolidatedHistoryLayout />,
                        children: [
                          {
                            index: true,
                            element: (
                              <FeatureFlagEnabledRoute
                                featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                                redirectTo="approved"
                              >
                                <ConsolidatedHistory />
                              </FeatureFlagEnabledRoute>
                            ),
                          },
                          {
                            path: 'approved',
                            element: (
                              <FeatureFlagProtectedRoute
                                featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                                redirectTo="/supplier/history"
                              >
                                <ConsolidatedHistory />
                              </FeatureFlagProtectedRoute>
                            ),
                          },
                          {
                            path: 'pending',
                            element: (
                              <FeatureFlagProtectedRoute
                                featureFlag="enterprise-ui_enablePendingAndFutureAwardsEnhancement"
                                redirectTo="/supplier/history"
                              >
                                <PendingConsolidatedHistory />
                              </FeatureFlagProtectedRoute>
                            ),
                          },
                        ],
                      },
                      {
                        path: 'recurring-rules',
                        element: <RecurringRulesManager />,
                      },
                      {
                        path: 'offer/:draftOfferUuid',
                        element: <DraftOffer />,
                      },
                      findYourCustomersRouteConfig,
                      accountSettingsRouteConfig,
                    ],
                  },
                ],
              },
              {
                path: 'auth',
                element: <Auth />,
                children: [
                  {
                    path: 'magic-login',
                    element: <AuthMagicLogin />,
                  },
                  {
                    path: 'impersonate',
                    element: <AuthImpersonation />,
                  },
                ],
              },
              {
                // catch all route for route that does not exist
                path: '*',
                element: <NavigateWithRedirect to="supplier/markets" />,
              },
            ],
          },
          {
            path: '*',
            element: <SomethingsWrong />,
          },
        ],
      },
    ],
    {
      basename,
    }
  );

  return <RouterProvider router={browserRouter} />;
};

export default Router;
