import useUserDetails from '@/data/useUserDetails';

const useUserAccountValues = () => {
  const { data: me } = useUserDetails(({ me }) => me);

  return {
    arDepartmentEmailAddress: me?.personalInfo?.arDepartmentEmailAddress ?? '',
    country: me?.personalInfo?.address?.country ?? '',
    dateDisplayFormat: me?.formatPreferences?.dateDisplayFormat ?? '',
    email: me?.emailAddress ?? '',
    firstName: me?.personalInfo?.firstName ?? '',
    fiscalYearEnd: me?.personalInfo?.fiscalYearEnd ?? '',
    industry: me?.personalInfo?.industry ?? '',
    lastName: me?.personalInfo?.lastName ?? '',
    locale: me?.personalInfo?.locale ?? '',
    numberDisplayFormat: me?.formatPreferences?.numberDisplayFormat ?? '',
    postalCode: me?.personalInfo?.address?.postalCode ?? '',
    primaryPhoneNumber: me?.personalInfo?.primaryPhoneNumber ?? '',
    role: me?.personalInfo?.role ?? '',
    secondaryPhoneNumber: me?.personalInfo?.secondaryPhoneNumber ?? '',
    useCustomFormatting: me?.formatPreferences?.useCustomFormatting ?? false,
  };
};

export default useUserAccountValues;
