import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

const useForgetUser = () => {
  return useMutation({
    mutationKey: ['forgetUser'],
    mutationFn: () => apiClient.post('/user/forget'),
  });
};

export default useForgetUser;
