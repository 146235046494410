import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Modal, ModalActions, ModalContent, ModalTitleInverse, useSnackbar } from '@c2fo/liquidity';
import useDeleteRecurringRule from '@/data/useDeleteRecurringRule';
import useEditInvoices from '@/data/useEditInvoices';
import useExchangeRates from '@/data/useExchangeRates';
import { TakerMarketWithRecurringRule } from '@/data/useRecurringRules';
import { useReporting } from '@/reporting';
import batchPromiseAllSettled from '@/utils/batchPromiseAllSettled';
import convertCurrency from '@/utils/convertCurrency';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import InvoiceReinclusion, { InvoiceReinclusionProps } from './InvoiceReinclusion';

interface DeleteAllRulesModalProps {
  hasFilterOrSearch: boolean;
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
  recurringRules: TakerMarketWithRecurringRule[];
}

const DeleteAllRulesModal = ({
  hasFilterOrSearch,
  onClose,
  onSuccess,
  open,
  recurringRules,
}: DeleteAllRulesModalProps) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { asNumber, asCurrency } = useLocaleFormat();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [contactSRM, setContactSRM] = useState(false);
  const [handleInvoices, setHandleInvoices] = useState<'include' | 'exclude'>('include');
  const { mutateAsync: deleteRecurringRule } = useDeleteRecurringRule();
  const { data: exchangeRates = {} } = useExchangeRates();
  const selectedCurrency = useSelectedCurrency();
  const { includeInvoices } = useEditInvoices();
  const excludedInvoicesCount = recurringRules.reduce(
    (acc, { takerMarket }) => acc + takerMarket.takerExcludedInvoiceCount,
    0
  );
  const excludedInvoicesAmount = asCurrency(
    recurringRules.reduce(
      (acc, { takerMarket }) =>
        acc +
        convertCurrency({
          amount: takerMarket.takerExcludedInvoiceAmount,
          from: takerMarket.currency,
          to: selectedCurrency,
          exchangeRates,
        }),
      0
    ),
    selectedCurrency
  );

  const handleClose = () => {
    setContactSRM(false);
    setHandleInvoices('include');
    onClose();
  };

  const handleInvoicesChange = (value: 'include' | 'exclude') => {
    setHandleInvoices(value);
  };

  const deleteAllRules = async () => {
    setLoading(true);
    track('recurring-rules::delete::invoice-reinclusion', { type: handleInvoices, ruleId: 'allRules' });

    const items = recurringRules.map(({ rule }) => ({
      id: rule.id,
      makerOrganizationUuid: rule.makerOrganizationUuid,
      marketId: rule.marketId,
      takerId: rule.takerId,
      takerUuid: rule.takerUuid,
      marketUuid: rule.marketUuid,
    }));

    await deleteRecurringRule(items, {
      onSuccess: () => {
        setLoading(false);
        onSuccess();
        handleClose();
        showSnackbar({ message: t('recurringRules.deleteAllRulesConfirm') });
      },
      onError: () => {
        setLoading(false);
        setShowError(true);
      },
    });

    if (handleInvoices === 'include' && excludedInvoicesCount > 0) {
      const includeTakerMarkets = recurringRules
        .map(({ takerMarket }) => takerMarket)
        .filter((tm) => tm.takerExcludedInvoiceCount > 0);
      await Promise.allSettled([
        await batchPromiseAllSettled({
          fn: async (argument) => {
            await includeInvoices(argument);
          },
          items: [{ filters: { all: true }, takerMarkets: includeTakerMarkets }],
          size: 5,
        }),
      ]);
    }

    return;
  };

  const invoiceReinclusionProps = {
    excludedInvoicesCount,
    excludedInvoicesAmount,
    handleInvoices,
    handleInvoicesChange,
  } satisfies InvoiceReinclusionProps;

  return (
    <Modal disableOutsideClickClose={loading} onClose={onClose} open={open} size="sm">
      <ModalTitleInverse>
        {hasFilterOrSearch ? t('recurringRules.deleteAllFilteredRules') : t('recurringRules.deleteAllRules')}
      </ModalTitleInverse>
      <ModalContent className="space-y-4">
        {showError && (
          <>
            {contactSRM ? (
              <Alert
                description={t('core.contactSrm.description')}
                full
                title={t('core.contactSrm.title')}
                type="info"
              />
            ) : (
              <Alert
                action={{
                  onClick: () => {
                    // fire track event for slack channel
                    track('recurring-rules::error', { type: 'delete', ruleId: 'allRules' });
                    setContactSRM(true);
                  },
                  text: t('core.contactSrm'),
                }}
                description={t('core.reachOutForAssistance')}
                full
                title={t('recurringRules.deleteRulesError')}
                type="error"
              />
            )}
          </>
        )}
        <div>
          {hasFilterOrSearch ? t('recurringRules.deleteRulesFilteredWarning') : t('recurringRules.deleteRulesWarning')}
        </div>
        <ul className="ml-4 list-disc space-y-1">
          <li>{t('recurringRules.deleteParticipationDescription')}</li>
          <li>{t('recurringRules.deleteFutureInvoicesDescription')}</li>
        </ul>
        <InvoiceReinclusion {...invoiceReinclusionProps} />
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} variant="secondary">
          {t('core.cancel')}
        </Button>
        <Button loading={loading} onClick={deleteAllRules} variant="destructive">
          {`${t('recurringRules.deleteAllRules')} (${asNumber({ value: recurringRules.length })})`}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DeleteAllRulesModal;
