import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import { FeatureFlagProtectedRoute } from '@/components/FeatureFlagProtectedRouting';
import { FullPageLoader } from '@/components/FullPageLoader';
import NavigateWithRedirect from '@/components/NavigateWithRedirect';
import AccountSettings from '@/pages/AccountSettings/pages/AccountSettings';
import NotificationSettings from '@/pages/AccountSettings/pages/NotificationSettings';
import RightToBeForgotten from '@/pages/AccountSettings/pages/RightToBeForgotten';

const App = lazy(() => import('@/pages/AccountSettings/App'));

export const accountSettingsBasename = 'account';

const accountSettingsRouteConfig: RouteObject = {
  path: accountSettingsBasename,
  element: (
    <Suspense fallback={<FullPageLoader />}>
      <FeatureFlagProtectedRoute featureFlag="enterprise-ui_enableAccountSettings">
        <App />
      </FeatureFlagProtectedRoute>
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <NavigateWithRedirect to="settings" />,
    },
    {
      path: 'settings',
      element: <AccountSettings />,
    },
    {
      path: 'notifications',
      element: <NotificationSettings />,
    },
    {
      path: 'right-to-be-forgotten',
      element: <RightToBeForgotten />,
    },
    { path: '*', element: <NavigateWithRedirect to="settings" /> },
  ],
};

export default accountSettingsRouteConfig;
