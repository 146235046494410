import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextInput,
  useSnackbar,
} from '@c2fo/liquidity';
import { PersonalInfoInput, Role } from '@/generated/gql/graphql';
import useUpdateUserProfile from '@/pages/AccountSettings/data/useUpdateUserProfile';
import useFormDropdownOptions from '@/pages/AccountSettings/utils/useFormDropdownOptions';

interface UserProfileFormInputs {
  firstName: string;
  lastName: string;
  mobilePhoneNumber: string;
  officePhoneNumber: string;
  role: string;
}

interface UserProfileFormProps {
  defaultValues: UserProfileFormInputs;
  onClose: () => void;
}

const UserProfileForm = ({ defaultValues, onClose }: UserProfileFormProps) => {
  const { t } = useTranslation();
  const showSnackbar = useSnackbar();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<UserProfileFormInputs>({ defaultValues });
  const { mutateAsync, isLoading } = useUpdateUserProfile();
  const { roleOptions } = useFormDropdownOptions();

  const onSubmit: SubmitHandler<UserProfileFormInputs> = async (data) => {
    const args = {
      firstName: data.firstName,
      lastName: data.lastName,
      role: data.role as Role,
      primaryPhoneNumber: data.officePhoneNumber,
      secondaryPhoneNumber: data.mobilePhoneNumber,
    } satisfies PersonalInfoInput;

    await mutateAsync(args, {
      onError: () => {
        showSnackbar({ message: t('accountSettings.error') });
      },
      onSuccess: () => {
        showSnackbar({ message: t('accountSettings.success') });
        onClose();
      },
    });
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="firstName" required>
            {t('accountSettings.firstName')}
          </Label>
          <TextInput
            id="firstName"
            className="w-full"
            hasError={!!errors.firstName}
            placeholder={t('accountSettings.firstName')}
            {...register('firstName', { required: true })}
          />
        </div>
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="lastName" required>
            {t('accountSettings.lastName')}
          </Label>
          <TextInput
            id="lastName"
            className="w-full"
            hasError={!!errors.lastName}
            placeholder={t('accountSettings.lastName')}
            {...register('lastName', { required: true })}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="w-full space-y-1 md:w-1/3">
          <Label htmlFor="role" required>
            {t('accountSettings.role')}
          </Label>
          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger aria-label={t('accountSettings.role')} hasError={!!errors.role}>
                  <SelectValue placeholder={t('accountSettings.role')} />
                </SelectTrigger>
                <SelectContent>
                  {roleOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="w-full space-y-1 md:w-1/3">
          <Label htmlFor="officePhoneNumber" required>
            {t('accountSettings.officePhoneNumber')}
          </Label>
          <TextInput
            id="officePhoneNumber"
            className="w-full"
            hasError={!!errors.officePhoneNumber}
            placeholder={t('accountSettings.officePhoneNumber')}
            {...register('officePhoneNumber', { required: true })}
          />
        </div>
        <div className="w-full space-y-1 md:w-1/3">
          <Label htmlFor="mobilePhoneNumber">{t('accountSettings.mobilePhoneNumber')}</Label>
          <TextInput
            id="mobilePhoneNumber"
            className="w-full"
            placeholder={t('accountSettings.mobilePhoneNumber')}
            {...register('mobilePhoneNumber')}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Button disabled={isLoading} onClick={onClose} variant="secondary">
          {t('core.cancel')}
        </Button>
        <Button loading={isLoading} type="submit">
          {t('core.save')}
        </Button>
      </div>
    </form>
  );
};

export default UserProfileForm;
