import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@c2fo/liquidity';
import { PencilIcon } from '@c2fo/liquidity/icons';
import beeLineSvg from '../assets/bee-line.svg';
import diverseDiscussionSvg from '../assets/diverse-discussion.svg?url';
import { useMyRequests } from '../data/useMyRequests';
import { CardLayoutGrid } from './CardLayoutGrid';
import { CustomerCard } from './CustomerCard';
import { RequestNewCustomerModal } from './RequestNewCustomerModal';
import { SuccessModal } from './SuccessModal';

export const RequestNewCustomers = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [showModal, setShowModal] = useState(state?.isNewCustomerModalOpen === true);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateUuid, setShowUpdateUuid] = useState<string | undefined>(undefined);
  const { data: requests = [] } = useMyRequests({ include: ['BUYERS_EXPANSION'] });
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const modifiedRequests = requests.map((request) => {
    const onClickEdit = () => {
      setShowUpdateModal(true);
      setShowUpdateUuid(request.uuid);
    };

    const isAllowedToEdit = request.status === 'NEW';

    return {
      ...request,
      onClick: isAllowedToEdit ? onClickEdit : undefined,
      iconButtonIcon: isAllowedToEdit ? PencilIcon : undefined,
    };
  });

  const onSuccessNewCustomerRequested = () => {
    setShowModal(false);
    setShowUpdateUuid(undefined);
    setShowSuccessModal(true);
  };

  return (
    <div className="py-6">
      <div className="flex flex-col gap-6">
        <div className="relative flex w-full overflow-hidden rounded-lg bg-primary-500 p-8 text-white">
          <div className="z-[1] flex flex-col items-start justify-center gap-6">
            <div className="flex flex-col items-start justify-center gap-2">
              <p className="m-0 p-0 text-xl font-medium lg:max-w-[75%]">
                {t('findYourCustomers.requestNewCustomer.title')}
              </p>
              <p className="m-0 p-0 text-xl font-medium lg:max-w-[75%]">
                {t('findYourCustomers.requestNewCustomer.subtitle')}
              </p>
              <Button variant="primary" size="lg" className="mt-4" onClick={() => setShowModal(true)}>
                {t('findYourCustomers.requestNewCustomer.cta')}
              </Button>
            </div>
          </div>
          <div className="hidden shrink-0 items-center justify-center lg:flex">
            <img src={diverseDiscussionSvg} alt="" className="h-64" />
          </div>
          <img src={beeLineSvg} alt="" className="absolute bottom-[-2px] right-0 z-0 hidden h-full lg:block" />
        </div>

        {/* In the future, if we support the "trending upvote" feature, we can show that here instead of hiding this section */}
        {!!modifiedRequests?.length && (
          <>
            <div className="flex flex-col gap-2">
              <h2 className="m-0 p-0 font-serif text-2xl font-normal">
                {t('findYourCustomers.requestNewCustomer.newRequests.title')}
              </h2>
              <p className="m-0 p-0 text-sm text-text-secondary">
                {t('findYourCustomers.requestNewCustomer.newRequests.subtitle')}
              </p>
            </div>

            <CardLayoutGrid>
              {modifiedRequests.map(({ uuid, ...props }) => (
                <CustomerCard key={uuid} {...props} />
              ))}
            </CardLayoutGrid>
          </>
        )}
      </div>

      <RequestNewCustomerModal open={showModal} setOpen={setShowModal} onSuccess={onSuccessNewCustomerRequested} />
      <RequestNewCustomerModal
        updateUuid={updateUuid}
        open={showUpdateModal}
        setOpen={setShowUpdateModal}
        onSuccess={onSuccessNewCustomerRequested}
      />
      <SuccessModal
        isOpen={showSuccessModal}
        setIsOpen={setShowSuccessModal}
        title={t('findYourCustomers.requestNewCustomer.success.title')}
        content={t('findYourCustomers.requestNewCustomer.success.description')}
      />
    </div>
  );
};
