import { initReactI18next } from 'react-i18next';
import { de, enUS, es, fr, it, ja, nl, zhCN, zhTW, ptBR, ru, tr } from 'date-fns/locale';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

export const SUPPORTED_LOCALES = [
  {
    default: true,
    code: 'en',
    language: 'English',
    worldCountriesLocale: 'en',
    cca3: 'USA',
    iso6391: 'en',
    dateFnsLocale: enUS,
  },
  {
    default: false,
    code: 'fr',
    language: 'Français',
    worldCountriesLocale: 'fra',
    cca3: 'FRA',
    iso6391: 'fr',
    dateFnsLocale: fr,
  },
  {
    default: false,
    code: 'zh-CN',
    language: '简体中文',
    worldCountriesLocale: 'zho',
    cca3: 'CHN',
    iso6391: 'zh_CN',
    dateFnsLocale: zhCN,
  },
  {
    default: false,
    code: 'zh-TW',
    language: '繁體中文',
    worldCountriesLocale: 'zho',
    cca3: 'TWN',
    iso6391: 'zh_TW',
    dateFnsLocale: zhTW,
  },
  {
    default: false,
    code: 'ja',
    language: '日本語',
    worldCountriesLocale: 'jpn',
    cca3: 'JPN',
    iso6391: 'ja',
    dateFnsLocale: ja,
  },
  {
    default: false,
    code: 'de',
    language: 'Deutsch',
    worldCountriesLocale: 'deu',
    cca3: 'DEU',
    iso6391: 'de',
    dateFnsLocale: de,
  },
  {
    default: false,
    code: 'es',
    language: 'Español',
    worldCountriesLocale: 'spa',
    cca3: 'ESP',
    iso6391: 'es',
    dateFnsLocale: es,
  },
  {
    default: false,
    code: 'nl',
    language: 'Nederlands',
    worldCountriesLocale: 'nld',
    cca3: 'NLD',
    iso6391: 'nl',
    dateFnsLocale: nl,
  },
  {
    default: false,
    code: 'it',
    language: 'Italiano',
    worldCountriesLocale: 'ita',
    cca3: 'ITA',
    iso6391: 'it',
    dateFnsLocale: it,
  },
  {
    default: false,
    code: 'ru',
    language: 'Pyccкий',
    worldCountriesLocale: 'rus',
    cca3: 'RUS',
    iso6391: 'ru',
    dateFnsLocale: ru,
  },
  {
    default: false,
    code: 'tr',
    language: 'Türkçe',
    worldCountriesLocale: 'tur',
    cca3: 'TUR',
    iso6391: 'tr',
    dateFnsLocale: tr,
  },
  {
    default: false,
    code: 'pt-BR',
    language: 'Português',
    worldCountriesLocale: 'por',
    cca3: 'POR',
    iso6391: 'pt_BR',
    dateFnsLocale: ptBR,
  },
] as const;

export const DEFAULT_LOCALE = SUPPORTED_LOCALES[0];
export const fallbackLng = DEFAULT_LOCALE.code;
export const defaultNS = 'nls';
export type LanguageCode = (typeof SUPPORTED_LOCALES)[number]['code'];
export const SUPPORTED_LANGUAGE_CODES: Array<LanguageCode> = SUPPORTED_LOCALES.map((l) => l.code);

export function getLanguage(locale: string) {
  return (SUPPORTED_LOCALES.find((l) => l.code === locale) ?? DEFAULT_LOCALE).language;
}

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(resourcesToBackend((language: string, namespace: string) => import(`./locales/${namespace}/${language}.json`)))
  .init({
    fallbackLng: 'en',
    fallbackNS: 'nls',
    ns: ['nls'],
    detection: {
      order: ['localStorage', 'querystring', 'htmlTag', 'navigator'],
      lookupCookie: 'locale',
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18next;
