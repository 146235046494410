import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, useSnackbar } from '@c2fo/liquidity';
import Modal, { ModalProps } from '@/components/Modal';
import { OfferConfigDetails, OfferConfigDetailsItem } from '@/components/OfferConfigDetails';
import { OfferDuration } from '@/components/OfferDuration';
import { OfferReviewDetails } from '@/components/OfferReviewDetails';
import { useConfirmAgreementsWhenRequired } from '@/data/useAgreements';
import useSubmitBenchmarkOffer from '@/data/useSubmitBenchmarkOffer';
import { TakerMarket } from '@/data/useTakerMarkets';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { Agreement } from '../agreements/Agreement';
import { SetOfferFormInputs } from '../nameYourRate/utils';
import { VariableRateDetails } from './DetailModal';
import useVariableRateDetails from './useVariableRateDetails';

type VariableRateFormInputs = Pick<SetOfferFormInputs, 'expireOn'>;

const VariableRateSetOfferComponent = ({
  onClose,
  open,
  takerMarket,
}: {
  onClose: () => void;
  open: boolean;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { asCurrency } = useLocaleFormat();
  const [step, setStep] = useState<1 | 2>(1);
  const { getVariableRateDetails } = useVariableRateDetails();
  const { formattedDiscountAmount, formattedEstimatedRate, formattedReferenceRate, formattedSpread, rateName } =
    getVariableRateDetails(takerMarket);
  const { mutateAsync: submitBenchmarkOffer, isLoading: submitBenchmarkOfferIsLoading } = useSubmitBenchmarkOffer();
  const [isSetExpirationDateEnabled] = useFeature('enterprise-ui_enableSetExpirationDate');
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired([takerMarket]);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const defaultValues: VariableRateFormInputs = {
    expireOn: takerMarket.disableAfterMarketClearsDate ?? null,
  };

  const { handleSubmit, reset, setValue, watch } = useForm<VariableRateFormInputs>({
    defaultValues,
  });

  useEffect(() => {
    if (takerMarket && !open) {
      reset(defaultValues);
      setStep(1);
      setIsAgreementsChecked(false);
      setIsAgreementsErrorNotChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takerMarket, open]);

  const expireOnValue = watch('expireOn');

  const offerConfigItems = useMemo(() => {
    const items: OfferConfigDetailsItem[] = [
      {
        label: t('taker.dictionary.availableAR.label'),
        value: asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency),
      },
    ];

    if (step === 2) {
      items.push({
        label: t('core.variableRate'),
        value: (
          <>
            {formattedEstimatedRate} ({formattedDiscountAmount})
          </>
        ),
      });

      if (expireOnValue) {
        items.push({
          label: t('taker.dictionary.expirationDate.label'),
          value: trimDateTimestamp(expireOnValue),
        });
      }
    }

    return items;
  }, [
    asCurrency,
    expireOnValue,
    formattedDiscountAmount,
    formattedEstimatedRate,
    step,
    t,
    takerMarket.currency,
    takerMarket.eligibleInvoiceAmount,
  ]);

  const onSubmit: SubmitHandler<VariableRateFormInputs> = async (data) => {
    try {
      await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
    } catch {
      setIsAgreementsErrorNotChecked(true);
      return;
    }

    const { expireOn } = data;

    const submitValues = {
      marketUuid: takerMarket.offerConfig.marketUuid,
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
      expireOn,
    };

    await submitBenchmarkOffer(submitValues, {
      onSuccess: () => showSnackbar({ message: t('offerSubmitDialog.offerSet') }),
      onError: () => showSnackbar({ message: t('offerSubmitDialog.offerSetError') }),
    });

    onClose();

    track('offer::variable-rate::submitted', {
      marketUuid: takerMarket.offerConfig.marketUuid,
      takerId: takerMarket.offerConfig.divisionId,
      supplierDivisionUuid: takerMarket.takerDivisionUuid,
      expireOn,
    });
  };

  const modalConfig: Partial<ModalProps> =
    step === 1
      ? {
          cancelText: t('core.cancel'),
          okButtonVariant: 'primary',
          okText: t('core.next'),
          onCancel: onClose,
          onOk: () => {
            setStep(2);
            track('offer-next::variable-rate::clicked');
          },
        }
      : {
          cancelText: t('core.back'),
          okButtonVariant: 'cta',
          okText: t('core.submit'),
          onCancel: () => setStep(1),
          onOk: handleSubmit(onSubmit),
        };

  return (
    <Modal
      fullWidthButtons
      loading={submitBenchmarkOfferIsLoading}
      onClose={onClose}
      open={open}
      subTitle={getTakerMarketName(takerMarket)}
      title={t('core.setVariableRateOfferLabel')}
      {...modalConfig}
    >
      <div className="space-y-6">
        {takerMarket.eligibleInvoiceAmount <= 0 && (
          <Alert
            variant="outlined"
            type="warning"
            full
            title={t('offerSubmitDialog.zeroApWarningTitle')}
            description={t('variableRate.details.zeroApDescription')}
          />
        )}
        <OfferConfigDetails items={offerConfigItems} />
        <div className="space-y-6">
          {step === 1 ? (
            <>
              <VariableRateDetails
                discountAmount={formattedDiscountAmount}
                estimatedRate={formattedEstimatedRate}
                referenceRate={formattedReferenceRate}
                spread={formattedSpread}
                rateName={rateName}
              />
              {isSetExpirationDateEnabled && (
                <>
                  <div className="border-t border-t-secondary-300" />
                  <OfferDuration
                    expireOn={watch('expireOn') ?? null}
                    setExpireOn={(isoDateString) => {
                      setValue('expireOn', isoDateString);
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <div className="space-y-4">
              <OfferReviewDetails takerMarket={takerMarket} />

              <Agreement
                takersMarkets={[takerMarket]}
                onChange={(v) => {
                  setIsAgreementsChecked(v);
                  setIsAgreementsErrorNotChecked(false);
                }}
              />
              {isAgreementsErrorNotChecked && (
                <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const VariableRateSetOffer = ({
  onClose,
  open,
  takerMarket,
}: {
  onClose: () => void;
  open: boolean;
  takerMarket?: TakerMarket | null;
}) => {
  return takerMarket ? <VariableRateSetOfferComponent onClose={onClose} open={open} takerMarket={takerMarket} /> : null;
};

export default VariableRateSetOffer;
