import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';

// marketClear: '/api/c2fo/taker/:takerId/market/:marketId/clearHistory',
// marketClearStats: '/api/c2fo/taker/:takerId/market/:marketId/clearHistoryStats',
// marketPendingClear: '/api/c2fo/taker/:takerId/market/:marketId/pendingClearHistory',
// awardFileByPayDateCsv: '/api/c2fo/taker/:takerId/market/:marketId/archive/:payDate/csv', // pass csv as {:format} param on backend
// awardFileByPayDatePdf: '/api/c2fo/taker/:takerId/market/:marketId/archive/:payDate/pdf', // pass pdf as {:format} param on backend
// awardFileByDateRange: '/api/c2fo/taker/:takerId/market/:marketId/archive/csv?startDate&endDate',
// pendingClearCsv: '/api/c2fo/taker/:takerId/market/:marketId/pending-clear/:id/csv',

export interface ApiParams {
  startDate: string;
  endDate: string;
  excludePending?: boolean;
  limit?: number;
  page?: number;
  order: string;
  orderDirection: string;
}

export interface ClearHistory {
  clearedAprWeightedAvg: number;
  clearedDiscountWeightedAvg: number;
  clearedDiscountedAmount: number;
  clearedDpeWeightedAvg: number;
  clearedEarn: number;
  clearedInvoiceAmount: number;
  clearedInvoiceCount: number;
  clearedYield: number;
  convertedDiscountedAmount: number;
  currency: string;
  hasCreditNote: boolean;
  isPendingClear: boolean;
  legacyMarketId: number;
  makerId: number;
  makerOrganizationUuid: string;
  marketAwardEventId: number;
  marketId: string;
  marketUuid: string;
  payDate: string;
  snapshotComplete: boolean;
  takerId: number;
  id: number;
}

export async function fetchClears(takerId: number, marketId: string, params: ApiParams) {
  const data = await apiClient
    .get(`api/c2fo/taker/${takerId}/market/${marketId}/clearHistory`, {
      searchParams: {
        ...params,
      },
    })
    .json<ClearHistory[]>();
  return data ?? [];
}

export default function useClearHistory(
  takerId: number,
  marketId: string,
  params: ApiParams,
  options?: UseQueryOptions<ClearHistory[]>
) {
  return useQuery({
    queryKey: ['clearHistory', takerId, marketId, params],
    queryFn: () => fetchClears(takerId, marketId, params),
    ...options,
  });
}

type ClearHistoryStatsParams = Pick<ApiParams, 'startDate' | 'endDate' | 'excludePending'>;

export interface ClearHistoryStats {
  clearedDiscountWeightedAvg: number;
  clearedEarn: number;
  clearedInvoiceAmount: number;
}

export async function fetchClearHistory(
  takerId: number,
  marketId: string,
  excludePending: boolean,
  params: ClearHistoryStatsParams
) {
  const data = await apiClient
    .get(`api/c2fo/taker/${takerId}/market/${marketId}/clearHistoryStats`, {
      searchParams: {
        ...params,
        // Only filter if exclude pending is true, if the the value is false, include final and pending clear in stats
        ...(excludePending && { excludePending: true }),
      },
    })
    .json<ClearHistoryStats[]>();

  return data?.[0];
}

export function useClearHistoryStats(
  takerId: number,
  marketId: string,
  excludePending: boolean,
  params: ClearHistoryStatsParams,
  options?: UseQueryOptions<ClearHistoryStats>
) {
  return useQuery({
    queryKey: ['clearHistoryStats', takerId, marketId, excludePending, params],
    queryFn: () => fetchClearHistory(takerId, marketId, excludePending, params),
    ...options,
  });
}

interface PendingClearResponse {
  clearedAprWeightedAvg: number;
  clearedDiscountWeightedAvg: number;
  clearedDiscountedAmount: number;
  clearedDpeWeightedAvg: number;
  clearedEarn: number;
  clearedInvoiceAmount: number;
  clearedInvoiceCount: number;
  clearedYield: number;
  created: string;
  currency: string;
  id: number;
  isPendingClear: boolean;
  makerId: number;
  makerOrganizationUuid: string;
  marketId: number;
  marketUuid: string;
  payDate: string;
  takerId: number;
  takerUuid: string;
}

export interface PendingClear extends Omit<PendingClearResponse, 'marketId'> {
  marketId: string;
}

export type PendingClearsParams = Pick<ApiParams, 'order' | 'orderDirection'>;

export async function fetchPendingClears(takerId: number, marketId: string, params: PendingClearsParams) {
  const data = await apiClient
    .get(`api/c2fo/taker/${takerId}/market/${marketId}/pendingClearHistory`, {
      searchParams: {
        ...params,
      },
    })
    .json<PendingClear[]>();
  return (data ?? []).map((item) => ({
    ...item,
    marketId: item.marketUuid,
  }));
}

export function usePendingClears(
  takerId: number,
  marketId: string,
  params: PendingClearsParams,
  options?: UseQueryOptions<PendingClear[]>
) {
  return useQuery({
    queryKey: ['pendingClears', takerId, marketId, params],
    queryFn: () => fetchPendingClears(takerId, marketId, params),
    keepPreviousData: true,
    ...options,
  });
}
