import { useMemo } from 'react';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import useTakerHistory from '@/data/useTakerHistory';
import { useReporting } from '@/reporting';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import useUrlState from '@/utils/useUrlState';
import HistoryTable from './HistoryTable';
import { dateRanges } from './utils';

const { last30Days: defaultRange } = dateRanges;

interface PendingConsolidatedHistoryUrlState {
  startDate: string;
  sort: string;
  limit: number;
  page: number;
}

const defaultState = {
  startDate: defaultRange.startDate,
  sort: 'payDate',
  limit: 25,
  page: 1,
} satisfies PendingConsolidatedHistoryUrlState;

/**
 * This component displays the pending history view for the consolidated history page.
 * Each page provides their own data and callbacks.
 */
export const PendingConsolidatedHistory = () => {
  const { track } = useReporting();
  const currency = useSelectedCurrency();
  const [urlState, setUrlState] = useUrlState<PendingConsolidatedHistoryUrlState>(defaultState);

  const params = useMemo(() => {
    return {
      startDate: urlState.startDate ?? defaultRange.startDate,
      sort: urlState.sort ?? defaultState.sort,
      limit: urlState.limit ?? defaultState.limit,
      page: urlState.page ?? defaultState.page,
    };
  }, [urlState]);

  const { data: pendingData, isFetching: pendingDataFetching } = useTakerHistory({
    ...params,
    // Setting endDate to an empty string so all future pending awards are returned
    endDate: '',
    currency,
    pendingClear: true,
  });

  return (
    <QueryBoundaries LoadingComponent={() => <Skeleton />}>
      <HistoryTable
        data={pendingData}
        currency={currency}
        sort={params.sort}
        setSort={(sort) => {
          setUrlState({ ...urlState, sort, page: 1 });
          track('history-sort::clicked', { key: sort });
        }}
        pageIndex={params.page}
        pageSize={params.limit}
        onPageChange={(page) => {
          setUrlState({ ...urlState, page });
          track('history-page::clicked');
        }}
        onPageSizeChange={(limit) => {
          setUrlState({ ...urlState, limit });
          track('history-page-size::clicked', { size: limit });
        }}
        displayType="pending"
        historyType="consolidated"
        loading={pendingDataFetching}
      />
    </QueryBoundaries>
  );
};
