import { useTranslation } from 'react-i18next';
import { SUPPORTED_LOCALES } from '@/i18n';

/**
 * hook to get translated dropdown options for the account settings form
 */
const useFormDropdownOptions = () => {
  const { t } = useTranslation();

  const getTranslatedOptionValues = (path: string) =>
    Object.entries<string>(t(path, { defaultValue: {}, returnObjects: true })).map(([value, label]) => ({
      label,
      value,
    }));

  return {
    countryOptions: getTranslatedOptionValues('accountSettings.countries'),
    industryOptions: getTranslatedOptionValues('accountSettings.industries'),
    localeOptions: SUPPORTED_LOCALES.map((locale) => ({
      label: locale.language,
      value: locale.code,
    })),
    monthOptions: getTranslatedOptionValues('accountSettings.months'),
    roleOptions: getTranslatedOptionValues('accountSettings.roles'),
  };
};

export default useFormDropdownOptions;
