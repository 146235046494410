export const formatDisplay = (value?: string | null) => {
  return value ? value : '—';
};

export const formatLabelFromOptions = (
  options: {
    label: string;
    value: string;
  }[],
  value?: string | null
) => {
  return formatDisplay(options.find((option) => option.value === value)?.label);
};
