import { Outlet, useSearchParams } from 'react-router-dom';
import NavigateWithRedirect from '@/components/NavigateWithRedirect';
import useFeature, { Feature } from '@/lib/features';

/**
 * Redirects if the feature flag is disabled.
 * Renders children if the feature flag is enabled.
 */
export const FeatureFlagProtectedRoute = ({
  children,
  featureFlag,
  redirectTo,
}: {
  featureFlag: Feature;
  children?: React.ReactNode;
  redirectTo?: string;
}) => {
  const [searchParams] = useSearchParams();
  const [enableFeatureFlag] = useFeature(featureFlag);

  if (!enableFeatureFlag) {
    let to = redirectTo ?? '/supplier/markets';

    if (searchParams) {
      to = `${to}?${new URLSearchParams(searchParams).toString()}`;
    }

    return <NavigateWithRedirect to={to} />;
  }

  return children ?? <Outlet />;
};

/**
 * Redirects if the feature flag is enabled.
 * Renders children if the feature flag is disabled.
 */
export const FeatureFlagEnabledRoute = ({
  children,
  featureFlag,
  redirectTo,
}: {
  featureFlag: Feature;
  children?: React.ReactNode;
  redirectTo?: string;
}) => {
  const [searchParams] = useSearchParams();
  const [enableFeatureFlag] = useFeature(featureFlag);

  if (enableFeatureFlag) {
    let to = redirectTo ?? '/supplier/markets';

    if (searchParams) {
      to = `${to}?${new URLSearchParams(searchParams).toString()}`;
    }

    return <NavigateWithRedirect to={to} />;
  }

  return children ?? <Outlet />;
};
