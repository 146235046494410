import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, HelperText, Label, TextInput, useSnackbar } from '@c2fo/liquidity';
import { PercentIcon } from '@c2fo/liquidity/icons';
import Modal, { ModalProps } from '@/components/Modal';
import { OfferConfigDetails, OfferConfigDetailsItem } from '@/components/OfferConfigDetails';
import { OfferDuration } from '@/components/OfferDuration';
import { OfferReviewDetails } from '@/components/OfferReviewDetails';
import { useConfirmAgreementsWhenRequired } from '@/data/useAgreements';
import useSubmitStaticOffer from '@/data/useSubmitStaticOffer';
import { TakerMarket } from '@/data/useTakerMarkets';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import getTakerMarketName from '@/utils/getTakerMarketName';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useRestrictions from '@/utils/useRestrictions';
import { Agreement } from '../agreements/Agreement';
import useFixedRateDetails from './useFixedRateDetails';

interface SetFixedOfferFormInputs {
  rate: string | null;
  expireOn: string | null;
}

const FixedRateSetOfferComponent = ({
  onClose,
  open,
  takerMarket,
}: {
  onClose: () => void;
  open: boolean;
  takerMarket: TakerMarket;
}) => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { getRestrictions } = useRestrictions();
  const { canEditOffers } = getRestrictions([takerMarket]);
  const { asCurrency, asPercent } = useLocaleFormat();
  const [step, setStep] = useState<1 | 2>(1);
  const { getFixedRateDetails } = useFixedRateDetails();
  const { formattedMarketRate, marketRate } = getFixedRateDetails(takerMarket);
  const { mutateAsync: submitStaticOffer, isLoading: submitStaticOfferIsLoading } = useSubmitStaticOffer();
  const [isSetExpirationDateEnabled] = useFeature('enterprise-ui_enableSetExpirationDate');
  const { validateAndConfirmAgreementsConditionally } = useConfirmAgreementsWhenRequired([takerMarket]);
  const [isAgreementsChecked, setIsAgreementsChecked] = useState(false);
  const [isAgreementsErrorNotChecked, setIsAgreementsErrorNotChecked] = useState(false);

  const defaultValues: SetFixedOfferFormInputs = {
    rate: takerMarket.offerConfig.maxApr
      ? String(takerMarket.offerConfig.maxApr)
      : marketRate
      ? String(marketRate)
      : '',
    expireOn: takerMarket.disableAfterMarketClearsDate ?? null,
  };

  const {
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    register,
    setError,
    reset,
    watch,
  } = useForm<SetFixedOfferFormInputs>({
    defaultValues: useMemo(() => {
      if (takerMarket) {
        return defaultValues;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [takerMarket]),
  });

  useEffect(() => {
    if (takerMarket) {
      reset(defaultValues);
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takerMarket]);

  const onSubmit: SubmitHandler<SetFixedOfferFormInputs> = async (data) => {
    if (!canEditOffers || !takerMarket) {
      return;
    }

    if (!marketRate) {
      console.error(
        `User attempted to submit an offer for a fixed rate market (${takerMarket.marketUuid}) that does not have a defined market rate.`
      );
      return;
    }

    const { rate, expireOn } = data;

    if (step === 1) {
      // step 1 - validate rate/rate type are set & send to step 2
      const validRate = !!rate && !isNaN(parseFloat(rate)) && parseFloat(rate) >= marketRate;

      const advanceStep = () => {
        setStep(2);
        track('offer-next::fixed-rate::clicked');
      };

      return !validRate
        ? setError('rate', {
            message: t('offerSubmitDialog.fixedRate.rateInputError'),
          })
        : advanceStep();
    } else {
      try {
        await validateAndConfirmAgreementsConditionally({ isAgreementsChecked });
      } catch {
        setIsAgreementsErrorNotChecked(true);
        return;
      }

      // step 2 - submit the form
      // make sure rate is positive and rounded to 2 decimal places
      const normalizedRate = Math.abs(Math.round(parseFloat(rate!) * 100) / 100);

      const submitValues = {
        rate: normalizedRate,
        marketUuid: takerMarket.marketUuid,
        supplierDivisionUuid: takerMarket.takerDivisionUuid,
        expireOn,
      };

      await submitStaticOffer(submitValues, {
        onSuccess: () => showSnackbar({ message: t('offerSubmitDialog.offerSet') }),
        onError: () => showSnackbar({ message: t('offerSubmitDialog.offerSetError') }),
      });

      onClose();
      track('offer::fixed-rate::submitted', submitValues);
    }
  };

  const expireOnValue = watch('expireOn');

  const offerConfigItems = useMemo(() => {
    const items: OfferConfigDetailsItem[] = [
      {
        label: t('taker.dictionary.availableAR.label'),
        value: asCurrency(takerMarket.eligibleInvoiceAmount, takerMarket.currency),
      },
      {
        label: t('core.fixedRate'),
        value: formattedMarketRate,
      },
    ];

    if (step === 2) {
      items.push({
        label: t('taker.offer'),
        value: `${asPercent({
          value: parseFloat(getValues('rate')!) / 100,
          decimals: 2,
        })} ${t('core.apr').toUpperCase()}`,
      });

      if (expireOnValue) {
        items.push({
          label: t('taker.dictionary.expirationDate.label'),
          value: trimDateTimestamp(expireOnValue),
        });
      }
    }

    return items;
  }, [
    asCurrency,
    asPercent,
    expireOnValue,
    formattedMarketRate,
    getValues,
    step,
    t,
    takerMarket.currency,
    takerMarket.eligibleInvoiceAmount,
  ]);

  const modalConfig: Partial<ModalProps> =
    step === 1
      ? {
          cancelText: t('core.cancel'),
          okButtonVariant: 'primary',
          okText: t('core.next'),
          onCancel: onClose,
          okButtonDisabled: !!errors.rate,
          onOk: handleSubmit(onSubmit),
        }
      : {
          cancelText: t('core.back'),
          okButtonVariant: 'cta',
          okText: t('core.submit'),
          onCancel: () => setStep(1),
          okButtonDisabled: false,
          onOk: handleSubmit(onSubmit),
        };

  return (
    <Modal
      fullWidthButtons
      loading={submitStaticOfferIsLoading}
      onClose={onClose}
      open={open}
      subTitle={getTakerMarketName(takerMarket)}
      title={t('core.setFixedRateOfferLabel')}
      {...modalConfig}
    >
      <div className="space-y-6">
        {takerMarket.eligibleInvoiceAmount <= 0 && (
          <Alert
            variant="outlined"
            type="warning"
            full
            title={t('offerSubmitDialog.zeroApWarningTitle')}
            description={t('offerSubmitDialog.zeroApWarningDescription')}
          />
        )}
        <OfferConfigDetails items={offerConfigItems} />
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {step === 1 ? (
            <>
              <p className="block text-base">{t('fixedRate.details.rateEntry', { rate: formattedMarketRate })}</p>
              <div className="space-y-1">
                <Label htmlFor="rate">{t('core.offerRate')}</Label>
                <div className="flex max-w-fit items-center gap-2">
                  <TextInput
                    id="rate"
                    className="w-full"
                    endIcon={PercentIcon}
                    hasError={!!errors.rate}
                    placeholder={t('offer.enterRate')}
                    type="text"
                    {...register('rate')}
                  />
                  <p className="text-nowrap text-base">{t('core.apr')}</p>
                </div>
                {!!errors.rate && <HelperText variant="error">{t('fixedRate.details.rateEntry.error')}</HelperText>}
              </div>

              {isSetExpirationDateEnabled && (
                <>
                  <div className="border-t border-t-secondary-300" />
                  <OfferDuration
                    expireOn={watch('expireOn') ?? null}
                    setExpireOn={(isoDateString) => {
                      setValue('expireOn', isoDateString);
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <div className="space-y-4">
              <OfferReviewDetails takerMarket={takerMarket} />
              <Agreement
                takersMarkets={[takerMarket]}
                onChange={(v) => {
                  setIsAgreementsChecked(v);
                  setIsAgreementsErrorNotChecked(false);
                }}
              />
              {isAgreementsErrorNotChecked && (
                <Alert type="error" variant="filled" description={t('agreements.error.checkbox')} full />
              )}
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};

const FixedRateSetOffer = ({
  onClose,
  open,
  takerMarket,
}: {
  onClose: () => void;
  open: boolean;
  takerMarket?: TakerMarket | null;
}) => {
  return takerMarket ? <FixedRateSetOfferComponent onClose={onClose} open={open} takerMarket={takerMarket} /> : null;
};

export default FixedRateSetOffer;
