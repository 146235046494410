import { useMutation, useQueryClient } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { UpdateCommunicationChannelMutationVariables } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';

const UPDATE_COMMUNICATION_CHANNEL = graphql(`
  mutation UpdateCommunicationChannel($channelUuid: ID!, $frequency: String!) {
    updateOneCommunicationChannel(input: { id: $channelUuid, update: { frequency: $frequency } }) {
      uuid
      channel
      frequency
    }
  }
`);

const useUpdateCommunicationChannel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateOneCommunicationChannel'],
    mutationFn: (args: UpdateCommunicationChannelMutationVariables) => {
      return gqlClient.request(UPDATE_COMMUNICATION_CHANNEL, args);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['communication-preferences']);
    },
  });
};

export default useUpdateCommunicationChannel;
