import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  useRefetchStatsQueries,
  useServerSideEventListeners,
} from '@/lib/serverSentEvents/useServerSideEventListeners';
import { fetchSEAInvoiceRules, TakerMarketWithRecurringRule } from '../../../data/useRecurringRules';
import useTakerMarkets from '../../../data/useTakerMarkets';
import sortRulesTable from './sortRulesTable';

// Utility function to chunk the array into batches of a specified size
const chunkTakerMarketsIntoBatches = <T>(array: T[], size: number): T[][] => {
  const result: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }

  return result;
};

export const useRefetchAndSubscribeForRules = () => {
  const queryClient = useQueryClient();
  const { refetchOneTakerMarket } = useRefetchStatsQueries();
  const { subscribeToMarketStats } = useServerSideEventListeners();
  const { data: allTakerMarkets = [] } = useTakerMarkets();

  const refetchSEASupplierMarketsRules = useCallback(
    async (takerMarkets: { marketUuid: string; takerUuid: string }[]) => {
      if (takerMarkets.length === 0) {
        return;
      }

      try {
        // The server cannot process if the REST query param length is too long, so we make the GET call in batches
        // Chunk the takerMarkets array into batches of 10
        const batches = chunkTakerMarketsIntoBatches(takerMarkets, 10);

        // Create an array of promises for each batch
        const promises = batches.map((tmBatch) =>
          fetchSEAInvoiceRules(allTakerMarkets, {
            supplierMarkets: tmBatch.map((tm) => ({
              marketUuid: tm.marketUuid,
              supplierDivisionUuid: tm.takerUuid,
            })),
          })
        );

        // Wait for all promises to resolve and collect the responses
        const responses = await Promise.all(promises);

        // Flatten the responses and collect them in updatedInvoiceRules
        const updatedInvoiceRules: TakerMarketWithRecurringRule[] = [];

        responses.forEach((response) => {
          updatedInvoiceRules.push(...response);
        });

        if (updatedInvoiceRules) {
          queryClient.setQueryData<TakerMarketWithRecurringRule[]>(['recurring-rules'], (prevData = []) => {
            // Create a copy of the previous data
            const newInvoiceRules = [...prevData];

            // Add the new rules for the specific supplier market
            updatedInvoiceRules.forEach((updatedRule) => {
              const invoiceRuleIndex = newInvoiceRules.findIndex(
                (invoiceRule) =>
                  invoiceRule.rule.marketUuid === updatedRule.rule.marketUuid &&
                  invoiceRule.rule.takerUuid === updatedRule.rule.takerUuid
              );

              if (invoiceRuleIndex !== -1) {
                // Update the existing rule
                newInvoiceRules[invoiceRuleIndex] = updatedRule;
              } else {
                // Add the new rule at the end of the array and later sort it based on eligibleInvoiceAmount
                newInvoiceRules.push(updatedRule);
              }
            });

            return sortRulesTable(newInvoiceRules, { key: 'eligibleInvoiceAmount', direction: 'desc' });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [queryClient, allTakerMarkets]
  );

  const refetchAndSubscribeRules = useCallback(
    ({
      takerMarkets,
    }: {
      takerMarkets: {
        marketUuid: string;
        takerId: number;
        takerUuid: string;
      }[];
    }) => {
      refetchSEASupplierMarketsRules(takerMarkets);

      takerMarkets.forEach(({ marketUuid, takerId }) => {
        refetchOneTakerMarket({ marketUuid, takerId });
        queryClient.refetchQueries({ queryKey: ['eligible-invoice-stats', marketUuid, takerId] });
        queryClient.refetchQueries({ queryKey: ['eligible-invoice-stats-filtered', marketUuid, takerId] });
      });

      takerMarkets.forEach(({ marketUuid, takerId }) => {
        subscribeToMarketStats({
          marketUuid,
          takerId,
        });
      });
    },
    [queryClient, refetchOneTakerMarket, subscribeToMarketStats, refetchSEASupplierMarketsRules]
  );

  return { refetchAndSubscribeRules };
};

export default useRefetchAndSubscribeForRules;
