/**
 * utility function to escape special characters
 *
 * example: *123* -> \*123\*
 * example: |1000| -> \|1000\|
 */

const escapeSpecialCharacters = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export default escapeSpecialCharacters;
