import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from '@c2fo/liquidity';
import useRefetchAndSubscribeForRules from '@/features/recurringRules/utils/useRefetchAndSubscribeForRules';
import apiClient from '@/lib/apiClient';
import { useReporting } from '@/reporting';
import { SEAInvoiceRulesUpsertCriteriaBody, SEAInvoiceRulesUpsertRequestBody } from './useCreateRecurringRule';
import { DeleteRecurringRuleArgument } from './useDeleteRecurringRule';
import { RecurringRuleCategory } from './useRecurringRules';

/**
 * hook for deleting a single criteria type for a recurring rule
 * uses a PATCH request with null values to update the rule with the removed criteria
 */
export interface DeleteRecurringRuleCriteriaArgument
  extends Pick<
    DeleteRecurringRuleArgument,
    'makerOrganizationUuid' | 'marketId' | 'marketUuid' | 'takerId' | 'takerUuid'
  > {
  id: string;
  criteriaType: RecurringRuleCategory;
}

const deleteSEAInvoiceRuleCriteria = (rule: DeleteRecurringRuleCriteriaArgument) => {
  const { criteriaType, marketUuid, takerUuid } = rule;

  /**
   * formats provided criteriaType to submit with null values to ensure any
   * removed criteria are updated correctly in SEA Api
   */
  let arg: SEAInvoiceRulesUpsertCriteriaBody = {};

  if (criteriaType === 'dpe') {
    arg = {
      dpe: { lte: null, gte: null },
    };
  }

  if (criteriaType === 'dueDate') {
    arg = {
      dueDate: { from: null, to: null },
    };
  }

  if (criteriaType === 'amount') {
    arg = {
      // USD is the default currency here cause we dont use currency or store it anywhere when we delete rule criteria
      invoiceAmount: { lte: null, gte: null, fromCurrency: 'USD' },
    };
  }

  if (criteriaType === 'invoiceId') {
    arg = {
      voucherIds: { exclude: null },
    };
  }

  return apiClient.put(`api/supplier-experience/invoice-rules`, {
    json: {
      groupingKey: 'SUPPLIER_MARKET',
      supplierMarkets: [
        {
          marketUuid: marketUuid,
          supplierDivisionUuid: takerUuid,
        },
      ],
      criteria: arg,
    } satisfies SEAInvoiceRulesUpsertRequestBody,
  });
};

const useDeleteRecurringRuleCriteria = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const { refetchAndSubscribeRules } = useRefetchAndSubscribeForRules();

  return useMutation({
    mutationKey: ['deleteRecurringRuleCriteria'],
    mutationFn: (rule: DeleteRecurringRuleCriteriaArgument) => {
      return deleteSEAInvoiceRuleCriteria(rule);
    },
    onSuccess: (_, variables) => {
      const { makerOrganizationUuid, marketId, marketUuid, takerId, takerUuid } = variables;

      track('recurring-rules::removed', {
        makerOrganizationUuid,
        rules: [{ type: variables.criteriaType }],
        takerMarkets: [{ marketId, takerId, marketUuid, takerUuid }],
      });
      refetchAndSubscribeRules({ takerMarkets: [{ marketUuid, takerId, takerUuid }] });
      showSnackbar({ message: t('recurringRules.deleteRuleSuccess') });
    },
  });
};

export default useDeleteRecurringRuleCriteria;
