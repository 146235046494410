import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BuyerExpansionRequestStatus } from './useBuyersExpansion';
import { BuyerLinkingRequestStatus } from './useBuyersLinkingRequests';

export const useStatusDetails = () => {
  const { t } = useTranslation();
  const getBuyerExpansionStatusDetails = useCallback(
    (status: BuyerExpansionRequestStatus): { label?: string; tooltip?: string } => {
      switch (status) {
        case 'NEW':
          return {
            label: t('findYourCustomers.tag.new.label'),
            tooltip: t('findYourCustomers.tag.new.tooltip'),
          };
        case 'PENDING':
          return {
            label: t('findYourCustomers.tag.pending.label'),
            tooltip: t('findYourCustomers.tag.pending.tooltip'),
          };
        case 'VERIFIED':
          return {
            label: t('findYourCustomers.tag.verified.label'),
            tooltip: t('findYourCustomers.tag.verified.tooltip'),
          };
        case 'REJECTED':
          return {
            label: t('findYourCustomers.tag.rejected.label'),
            tooltip: t('findYourCustomers.tag.rejected.tooltip'),
          };
        case 'ONBOARDING':
          return {
            label: t('findYourCustomers.tag.onboarding.label'),
            tooltip: t('findYourCustomers.tag.onboarding.tooltip'),
          };
        case 'ONBOARDED':
          return {
            label: t('findYourCustomers.tag.onboarded.label'),
            tooltip: undefined,
          };
        case 'UNKNOWN':
        default:
          return {
            label: undefined,
            tooltip: undefined,
          };
      }
    },
    [t]
  );

  const getBuyerLinkingRequestStatusDetails = useCallback(
    (status: BuyerLinkingRequestStatus): { label?: string; tooltip?: string } => {
      switch (status) {
        case 'IN_PROGRESS':
          return {
            label: t('findYourCustomers.tag.inProgress.label'),
            tooltip: t('findYourCustomers.tag.inProgress.tooltip'),
          };
        case 'COMPLETED':
          return {
            label: t('findYourCustomers.tag.completed.label'),
            tooltip: t('findYourCustomers.tag.completed.tooltip'),
          };
        case 'FAILED':
          return {
            label: t('findYourCustomers.tag.failed.label'),
            tooltip: t('findYourCustomers.tag.failed.tooltip'),
          };
        case 'UNKNOWN':
        default:
          return {
            label: undefined,
            tooltip: undefined,
          };
      }
    },
    [t]
  );

  return {
    getBuyerExpansionStatusDetails,
    getBuyerLinkingRequestStatusDetails,
  };
};
