import { useQuery } from '@tanstack/react-query';
import { paths } from '@/generated/supplier-experience-api';
import apiClient from '@/lib/apiClient';

type Response = paths['/buyers/linking-requests']['get']['responses']['200']['content']['application/json'];
type ResponseData = Response['data'];

export type BuyerLinkingRequestStatus = ResponseData[0]['status'];

const fetchData = async () => {
  const response = await apiClient.get(`api/supplier-experience/buyers/linking-requests`).json<Response>();

  return response.data;
};

const useBuyersLinkingRequests = <TData = ResponseData>(select?: (data: ResponseData) => TData) => {
  return useQuery({
    queryKey: ['buyers-linking-requests'],
    queryFn: () => fetchData(),
    select,
  });
};

export default useBuyersLinkingRequests;
