interface CustomerIconProps {
  buyerLogo?: string | null;
  buyerName: string;
}

const colors = ['#06a76c', '#07c57f', '#369DD9', '#B778DD', '#DB5DB1', '#EB6D00', '#EE9709', '#9BD045'];
/**
 * Returns a consistent color for a given string from a list of colors.
 */
function getColor(str: string): string {
  const hash = Array.from(str).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colors[hash % colors.length];
}

export const CustomerIcon = ({ buyerName, buyerLogo }: CustomerIconProps) => {
  const initials = buyerName
    .split(' ')
    .map((word) => word[0])
    .join('')
    .slice(0, 3);

  const backgroundColor = getColor(buyerName);

  return (
    <>
      {buyerLogo ? (
        <img src={buyerLogo} alt={buyerName} className="max-h-28 max-w-28" />
      ) : (
        <div
          className="flex h-[70px] w-[70px] items-center justify-center rounded-full text-2xl"
          style={{ backgroundColor }}
        >
          {initials}
        </div>
      )}
    </>
  );
};
