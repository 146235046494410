import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import apiClient from '@/lib/apiClient';
import { getSessionTrackingData } from '@/reporting/sessionTrackingData';
import { useToken } from '@/utils/token';
import useNewExperienceEnabled from '@/utils/useNewExperienceEnabled';
import useDataDog, { DataDogProvider } from './DataDog';
import { TrackEventNames } from './trackEventNames';
import { C2foPayload, ClientMeta, ReportAttributes } from './types';

const ReportingContext = createContext<{
  namespace: string;
} | null>(null);

export const mutationKeyTrack = ['track'];

/**
 * Provides access to the reporting interfaces and all of the reporting providers are set up from this location.
 */
export function ReportingProvider({
  children,
  namespace = 'enterprise',
}: {
  children: ReactNode;
  /**
   * The namespace to use for all reporting events.
   * This is used to namespace all events so that they can be filtered in the reporting tools.
   */
  namespace: string;
}) {
  return (
    <ReportingContext.Provider value={{ namespace }}>
      <DataDogProvider>{children}</DataDogProvider>
    </ReportingContext.Provider>
  );
}

/**
 * Hook for tracking events and identifying users.
 */
export function useReporting() {
  const context = useContext(ReportingContext);

  if (!context) {
    throw new Error('useReporting must be used within a ReportingProvider');
  }

  const { namespace } = context;
  const { tokenContent } = useToken();
  const authServiceUuid = tokenContent?.payload?.user?.uuid ?? null;
  const sessionUuid = tokenContent?.payload?.user?.sessionUuid ?? null;
  const { addAction: dataDogAddAction } = useDataDog();
  const newExperienceEnabled = useNewExperienceEnabled();
  const sessionTrackingData = useMemo(() => getSessionTrackingData(), []);
  const uri = window?.location?.href ?? null;
  const uriQueryParams = window?.location?.search ?? null;
  const uriStateName = window?.location?.pathname ?? null;
  const clientMeta = useMemo<ClientMeta>(
    () => ({
      createdDateTime: new Date(),
      sessionUuid,
      uriStateName,
      uri,
      uriQueryParams,
      ...sessionTrackingData,
    }),
    [sessionUuid, sessionTrackingData, uri, uriQueryParams, uriStateName]
  );

  const { mutateAsync: postTrack } = useMutation({
    mutationKey: mutationKeyTrack,
    mutationFn: async (payload: C2foPayload) => {
      // during development, log out what will be sent to the analytics api
      if (import.meta.env.MODE === 'development') {
        console.log('track event', { [payload.eventName]: payload });
      }

      if (import.meta.env.VITE_ENABLE_ANALYTICS === 'true') {
        return apiClient.post('api/analytics/track', {
          json: payload,
        });
      }
    },
  });

  const track = useCallback(
    async <T extends ReportAttributes>(event: TrackEventNames, attributes?: T) => {
      if (authServiceUuid) {
        const namespacedEvent = `${namespace}::${event}`;

        const properties: ReportAttributes = {
          clientMeta,
          ...(attributes && { eventMeta: attributes }),
          ...(tokenContent?.payload.impersonationSubject?.uuid && {
            impersonationSubjectUuid: tokenContent.payload.impersonationSubject.uuid,
          }),
          newExperienceEnabled,
        };

        const payload: C2foPayload = {
          appId: `${import.meta.env.VITE_APP_NAME}`,
          authServiceUuid,
          eventName: namespacedEvent,
          properties,
          sendToAtlasQueue: true,
          sessionUuid: clientMeta.sessionUuid,
        };

        try {
          dataDogAddAction(namespacedEvent, { ...properties });
          await postTrack({ ...payload, properties: { ...properties, sessionUuid: clientMeta.sessionUuid } });
        } catch (error) {
          // Don't block app functionality if tracking fails for some reason.
        }
      }
    },
    [authServiceUuid, clientMeta, dataDogAddAction, namespace, newExperienceEnabled, postTrack, tokenContent]
  );

  return { track };
}
