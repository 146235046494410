import { useTranslation } from 'react-i18next';
import useLocaleFormat from '@/utils/useLocaleFormat';
import { CustomerCardProps } from '../components/CustomerCard';
import useBuyersExpansion, { BuyerExpansionRequestStatus } from './useBuyersExpansion';
import useBuyersLinkingRequests, { BuyerLinkingRequestStatus } from './useBuyersLinkingRequests';
import { useStatusDetails } from './useStatusDetails';

interface MyRequest extends CustomerCardProps {
  uuid: string;
  status: BuyerLinkingRequestStatus | BuyerExpansionRequestStatus;
}
[];

export const useMyRequests = ({
  include,
}: {
  include: ('BUYERS_EXPANSION' | 'BUYER_LINKING')[];
}): { data: MyRequest[]; isLoading: boolean } => {
  const { t } = useTranslation();
  const { getBuyerExpansionStatusDetails, getBuyerLinkingRequestStatusDetails } = useStatusDetails();
  const { asDateString } = useLocaleFormat();

  const { data: buyersExpansionData = [], isLoading: isLoadingBuyersExpansion } = useBuyersExpansion((data) => {
    return data.map((item) => {
      const { label, tooltip } = getBuyerExpansionStatusDetails(item.status);
      return {
        uuid: item.uuid,
        status: item.status,
        customerName: item.buyerName ?? '-',
        chipLabelText: label,
        chipTooltipText: tooltip,
        additionalDetails: [
          {
            key: 'requestedOnDate',
            value: item.requestedOnDate
              ? t('findYourCustomers.requestedOnDate', { date: asDateString(item.requestedOnDate, { month: 'short' }) })
              : undefined,
          },
          { key: 'caseId', value: item.caseId ? t('findYourCustomers.caseId', { caseId: item.caseId }) : undefined },
        ],
      } satisfies MyRequest;
    });
  });

  const { data: buyersLinkingRequestsData = [], isLoading: isLoadingBuyersLinkingRequests } = useBuyersLinkingRequests(
    (data) => {
      return data.map((item) => {
        const { label, tooltip } = getBuyerLinkingRequestStatusDetails(item.status);
        return {
          uuid: item.uuid,
          status: item.status,
          customerName: item.buyerName ?? '-',
          customerLogoUrl: item.logoUrl,
          chipLabelText: label,
          chipTooltipText: tooltip,
          additionalDetails: [
            {
              key: 'requestedOnDate',
              value: item.requestedOnDate
                ? t('findYourCustomers.requestedOnDate', {
                    date: asDateString(item.requestedOnDate, { month: 'short' }),
                  })
                : undefined,
            },
            { key: 'caseId', value: item.caseId ? t('findYourCustomers.caseId', { caseId: item.caseId }) : undefined },
          ],
        } satisfies MyRequest;
      });
    }
  );

  const requests = [];

  if (include.includes('BUYERS_EXPANSION')) {
    requests.push(...buyersExpansionData);
  }

  if (include.includes('BUYER_LINKING')) {
    requests.push(...buyersLinkingRequestsData);
  }

  return {
    data: requests.sort((a, b) => a.customerName.localeCompare(b.customerName)),
    isLoading: isLoadingBuyersExpansion || isLoadingBuyersLinkingRequests,
  };
};
