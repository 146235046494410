import { SetStateAction, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

const useUrlState = <S>(initialState: S | (() => S)) => {
  type State = Partial<S>;

  const location = useLocation();
  const navigate = useNavigate();
  const initialStateRef = useRef(typeof initialState === 'function' ? (initialState as () => S)() : initialState || {});

  const queryFromUrl = useMemo(
    () =>
      qs.parse(location.search, {
        arrayLimit: 48,
        ignoreQueryPrefix: true,
        decoder(str, _decoder, charset) {
          const strWithoutPlus = str.replace(/\+/g, ' ');
          if (charset === 'iso-8859-1') {
            // unescape never throws, no try...catch needed:
            return strWithoutPlus.replace(/%[\da-f]{2}/gi, unescape);
          }

          if (/^(?:\d+|\d*\.\d+)$/.test(str)) {
            return parseFloat(str);
          }

          const keywords = {
            true: true,
            false: false,
            null: null,
            undefined,
          };

          if (str in keywords) {
            return keywords[str as keyof typeof keywords];
          }

          // utf-8
          try {
            return decodeURIComponent(strWithoutPlus);
          } catch (e) {
            return strWithoutPlus;
          }
        },
      }),
    [location.search]
  );

  const targetQuery: State = useMemo(
    () => ({
      ...initialStateRef.current,
      ...queryFromUrl,
    }),
    [queryFromUrl]
  );

  const setState = (s: SetStateAction<State>) => {
    const newQuery = typeof s === 'function' ? s(targetQuery) : s;

    navigate(
      {
        hash: location.hash,
        search: qs.stringify({ ...queryFromUrl, ...newQuery }) || '?',
      },
      {
        preventScrollReset: true,
        replace: false,
        state: location.state,
      }
    );
  };

  return [targetQuery, setState] as const;
};

export default useUrlState;
