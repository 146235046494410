import { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@c2fo/liquidity';
import colors from '@c2fo/liquidity/colors';
import { CaretDownFilledIcon } from '@c2fo/liquidity/icons';
import useRecentAwards, { Award } from '@/data/useRecentAwards';
import useFeature from '@/lib/features';
import { useReporting } from '@/reporting';
import { trimDateTimestamp } from '@/utils/trimDateTimestamp';
import useLocaleFormat from '@/utils/useLocaleFormat';
import useSmbUser from '@/utils/useSmbUser';
import QueryBoundaries from '../QueryBoundaries';

/**
 * formats the supplier name for display
 */
function getSupplierName({ takerDivisionName, takerOrganizationName }: Award) {
  let name = takerDivisionName;

  if (takerDivisionName.toLowerCase() !== takerOrganizationName.toLowerCase()) {
    name += ` - ${takerOrganizationName}`;
  }

  return name;
}

const AwardLink = forwardRef<HTMLAnchorElement, { award: Award; onSelect?: () => void }>(({ award, onSelect }, ref) => {
  const { track } = useReporting();
  const { asRelativeDate, asCurrency } = useLocaleFormat();
  const [enableAwardsEnhancement] = useFeature('enterprise-ui_enablePendingAndFutureAwardsEnhancement');

  return (
    <Link
      ref={ref}
      className="flex grow flex-col justify-between gap-1 border-b px-4 py-3 font-normal text-text-primary transition-colors duration-200 last:border-b-0 hover:bg-lightBlue-50"
      onClick={() => {
        onSelect?.();
        track('recent-award-nav::clicked');
      }}
      to={
        award.pending && enableAwardsEnhancement
          ? `/supplier/markets/${award.marketUuid}/division/${award.takerId}/history/pending`
          : `/supplier/markets/${award.marketUuid}/division/${award.takerId}/history?startDate=${trimDateTimestamp(
              award.payDate
            )}&endDate=${trimDateTimestamp(award.payDate)}`
      }
      type="button"
    >
      <div className="flex justify-between gap-4">
        <div className="w-44 truncate lg:w-32">{award.makerDivisionName}</div>
        <div>{asCurrency(award.clearAmount, award.currency)}</div>
      </div>
      <div className="flex justify-between gap-4">
        <div className="w-44 truncate text-sm text-text-secondary lg:w-32">{getSupplierName(award)}</div>
        <div className="text-sm text-text-secondary">{asRelativeDate(award.clearTime)}</div>
      </div>
    </Link>
  );
});
AwardLink.displayName = 'AwardLink';

const RecentAwardsDropdownComponent = ({
  itemsOnly = false,
  onClose,
}: {
  itemsOnly?: boolean;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { data: awards } = useRecentAwards();
  const isSmbUser = useSmbUser();
  const [menuOpen, setMenuOpen] = useState(false);

  return !isSmbUser && awards?.length ? (
    <>
      {itemsOnly ? (
        awards?.map((award) => (
          <div key={award.id} className="relative flex w-full border-b last:border-b-0">
            <AwardLink award={award} onSelect={onClose} />
          </div>
        ))
      ) : (
        <DropdownMenu open={menuOpen}>
          <DropdownMenuTrigger onClick={() => setMenuOpen(true)}>
            <div className="flex items-center whitespace-nowrap border-b-2 border-transparent px-4">
              <div className="flex items-center space-x-1">
                <div>{t('taker.recentAwardsDropdown.title', 'Recent Awards')}</div>
                <CaretDownFilledIcon className="h-5 w-5" aria-hidden="true" fill={colors.secondary[500]} />
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="-mt-1.5 max-h-96 overflow-y-scroll py-0"
            onInteractOutside={() => setMenuOpen(false)}
          >
            {awards.map((award) => (
              <DropdownMenuItem key={award.id} asChild>
                <AwardLink award={award} onSelect={() => setMenuOpen(false)} />
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  ) : null;
};

const RecentAwardsDropdown = ({ itemsOnly = false, onClose }: { itemsOnly?: boolean; onClose?: () => void }) => (
  <QueryBoundaries>
    <RecentAwardsDropdownComponent itemsOnly={itemsOnly} onClose={onClose} />
  </QueryBoundaries>
);

export default RecentAwardsDropdown;
