import { useQuery } from '@tanstack/react-query';
import { graphql } from '@/generated/gql/gql';
import { CommunicationPreferencesQuery } from '@/generated/gql/graphql';
import { gqlClient } from '@/lib/gqlClient';

const COMMUNICATION_PREFERENCES = graphql(`
  query CommunicationPreferences {
    communicationPreferences {
      uuid
      notificationKey
      channels {
        uuid
        channel
        frequency
      }
    }
  }
`);

const LOAD_DEFAULT_PREFERENCES = graphql(`
  mutation LoadDefaultPreferences {
    loadDefaultPreferences {
      uuid
      authServiceUuid
      notificationKey
      communicationType
      channels {
        uuid
        channel
        frequency
      }
    }
  }
`);

let hasLoadedDefaults = false;

async function fetchCommunicationPreferences(): Promise<CommunicationPreferencesQuery> {
  // load the defaults once, this API will not overwrite custom preferences
  if (!hasLoadedDefaults) {
    hasLoadedDefaults = true;
    await gqlClient.request(LOAD_DEFAULT_PREFERENCES);
  }

  return await gqlClient.request(COMMUNICATION_PREFERENCES);
}

const useCommunicationPreferences = () => {
  return useQuery({
    queryKey: ['communication-preferences'],
    queryFn: fetchCommunicationPreferences,
  });
};

export default useCommunicationPreferences;
