import { useMemo } from 'react';
import { useMarketLayoutOutletContext } from '@/components/MarketLayout';
import QueryBoundaries from '@/components/QueryBoundaries';
import Skeleton from '@/components/Skeleton';
import { usePendingClears } from '@/data/useClearHistory';
import { useReporting } from '@/reporting';
import useParams from '@/utils/useParams';
import HistoryTable from './HistoryTable';

/**
 * This component displays the pending history view for the market history page.
 * Each page provides their own data and callbacks.
 */
export const PendingMarketHistory = () => {
  const { track } = useReporting();
  const [searchParams, setParams] = useParams();

  const {
    urlParams: { marketId, takerId },
  } = useMarketLayoutOutletContext();

  const params = useMemo(() => {
    return {
      limit: Number(searchParams.get('limit') ?? 25),
      page: Number(searchParams.get('page') ?? 1),
    };
  }, [searchParams]);

  const { data: pendingData, isFetching: pendingDataFetching } = usePendingClears(takerId, marketId, {
    order: 'payDate',
    orderDirection: 'desc',
  });

  return (
    <QueryBoundaries LoadingComponent={() => <Skeleton />}>
      <HistoryTable
        data={pendingData}
        currency={pendingData?.[0]?.currency || 'USD'}
        sort={''}
        pageIndex={params.page}
        pageSize={params.limit}
        onPageChange={(page) => {
          setParams({ page: `${page}` });
          track('history-page::clicked');
        }}
        onPageSizeChange={(limit) => {
          setParams({ limit: `${limit}` });
          track('history-page-size::clicked', { size: limit });
        }}
        displayType="pending"
        historyType="market"
        loading={pendingDataFetching}
      />
    </QueryBoundaries>
  );
};
