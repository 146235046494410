import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Toggle,
  useSnackbar,
  useSnackbarDispatchContext,
} from '@c2fo/liquidity';
import { CommunicationPreferencesQuery } from '@/generated/gql/graphql';
import { FullPageLoader } from '@/components/FullPageLoader';
import QueryBoundaries from '@/components/QueryBoundaries';
import CardForm from '@/pages/AccountSettings//components/CardForm';
import Header from '@/pages/AccountSettings/components/Header';
import Unsubscribe from '@/pages/AccountSettings/components/Unsubcribe';
import useCommunicationPreferences from '@/pages/AccountSettings/data/useCommunicationPreferences';
import useUpdateCommunicationChannel from '@/pages/AccountSettings/data/useUpdateCommunicationChannel';
import { useReporting } from '@/reporting';

type NotificationKey =
  | 'account.earlypay.activity'
  | 'account.invoice.activity'
  | 'c2fo.newsletter'
  | 'c2fo.program.updates'
  | 'c2fo.special.offers'
  | 'c2fo.webinar.invitations';

interface NotificationGroup {
  notificationKey: NotificationKey;
  subtitle: string;
  title: string;
}

interface NotificationGroups {
  c2foUpdates: NotificationGroup[];
  yourAccount: NotificationGroup[];
}

const notificationGroups: NotificationGroups = {
  c2foUpdates: [
    {
      notificationKey: 'c2fo.special.offers',
      subtitle: 'accountSettings.notificationSettingsSpecialOffersSubtitle',
      title: 'accountSettings.notificationSettingsSpecialOffers',
    },
    {
      notificationKey: 'c2fo.newsletter',
      subtitle: 'accountSettings.notificationSettingsNewsletterSubtitle',
      title: 'accountSettings.notificationSettingsNewsletter',
    },
    {
      notificationKey: 'c2fo.webinar.invitations',
      subtitle: 'accountSettings.notificationSettingsWebinarInvitationsSubtitle',
      title: 'accountSettings.notificationSettingsWebinarInvitations',
    },
  ],
  yourAccount: [
    {
      notificationKey: 'account.invoice.activity',
      subtitle: 'accountSettings.notificationSettingsInvoiceActivitySubtitle',
      title: 'accountSettings.notificationSettingsInvoiceActivity',
    },
    {
      notificationKey: 'account.earlypay.activity',
      subtitle: 'accountSettings.notificationSettingsEarlyPayActivitySubtitle',
      title: 'accountSettings.notificationSettingsEarlyPayActivity',
    },
    {
      notificationKey: 'c2fo.program.updates',
      subtitle: 'accountSettings.notificationSettingsProgramUpdatesSubtitle',
      title: 'accountSettings.notificationSettingsProgramUpdates',
    },
  ],
};

const getNotificationPreference = (
  notificationKey: NotificationKey,
  communicationPreferences?: CommunicationPreferencesQuery
) => {
  const preference = communicationPreferences?.communicationPreferences
    .find((item) => item.notificationKey === notificationKey)
    ?.channels.find((channel) => channel.channel === 'EMAIL');

  return {
    ...preference,
    channel: preference?.channel ?? '',
    frequency: !preference?.frequency || preference?.frequency === 'NEVER' ? '' : preference?.frequency,
    uuid: preference?.uuid ?? '',
  };
};

const NotificationToggle = ({
  checked,
  disabled,
  loading,
  notificationKey,
  onCheckedChange,
}: {
  checked: boolean;
  disabled: boolean;
  loading: boolean;
  notificationKey: NotificationKey;
  onCheckedChange: (checked: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-3">
      <Label htmlFor={notificationKey}>{t('accountSettings.notificationSettingsEmail')}</Label>
      <Toggle
        id={notificationKey}
        name={notificationKey}
        checked={checked}
        disabled={disabled}
        loading={loading}
        onCheckedChange={onCheckedChange}
      />
    </div>
  );
};

const NotificationSettingsComponent = () => {
  const { t } = useTranslation();
  const { track } = useReporting();
  const showSnackbar = useSnackbar();
  const snackbarDispatch = useSnackbarDispatchContext();
  const { mutateAsync } = useUpdateCommunicationChannel();
  const { data: communicationPreferences } = useCommunicationPreferences();
  const [loadingNotification, setLoadingNotification] = useState<string>('');

  const invoiceAlertFrequency =
    communicationPreferences?.communicationPreferences
      .find((item) => item.notificationKey === 'account.invoice.activity')
      ?.channels.filter((channel) => channel.frequency !== 'NEVER')
      ?.find((channel) => channel.channel === 'EMAIL')?.frequency ?? '';

  const updateNotificationPreference = async ({
    checked,
    frequency,
    notificationKey,
  }: {
    notificationKey: NotificationKey;
    checked?: boolean;
    frequency?: string;
  }) => {
    const channelUuid = getNotificationPreference(notificationKey, communicationPreferences).uuid;

    if (channelUuid) {
      const loadingKey = frequency ? `${channelUuid}-frequency` : `${channelUuid}-uuid`;
      const updatedFrequency = frequency ? frequency : checked ? 'DAILY' : 'NEVER';

      setLoadingNotification(loadingKey);

      snackbarDispatch?.({
        type: 'enqueue',
        message: {
          id: loadingKey,
          closeable: false,
          content: t('accountSettings.updatingSettings'),
          loading: true,
          open: true,
        },
      });

      await mutateAsync(
        { channelUuid, frequency: updatedFrequency },
        {
          onSettled: () => {
            setLoadingNotification('');
            snackbarDispatch?.({ type: 'dequeue', id: loadingKey });
          },
          onSuccess: () => {
            showSnackbar({ message: t('accountSettings.settingsUpdated') });

            if (updatedFrequency === 'NEVER') {
              track('notifs::category::channel::opt-out', {
                channel: 'EMAIL',
                communicationCategory: notificationKey,
              });
            } else {
              track('notifs::category::channel::opt-in', {
                channel: 'EMAIL',
                communicationCategory: notificationKey,
                frequency: updatedFrequency,
              });
            }
          },
          onError: () => {
            showSnackbar({ message: t('accountSettings.errorUpdatingSettings') });
          },
        }
      );
    } else {
      showSnackbar({ message: t('accountSettings.errorUpdatingSettings') });
    }
  };

  return (
    <div>
      <Header title={t('accountSettings.notificationSettings')} />
      <div className="space-y-12">
        {/* your account */}
        <CardForm.Card
          title={t('accountSettings.notificationSettingsYourAccount')}
          subtitle={t('accountSettings.notificationSettingsYourAccountSubtitle')}
          content={
            <CardForm.CardContent className="space-y-6">
              {notificationGroups.yourAccount.map(({ title, subtitle, notificationKey }) => {
                const notificationPreference = getNotificationPreference(notificationKey, communicationPreferences);

                return (
                  <div
                    key={notificationKey}
                    className="flex flex-col justify-between gap-4 border-b pb-6 last:border-b-0 last:pb-0 md:flex-row md:items-center md:gap-6"
                  >
                    <div>
                      <div className="font-medium">{t(title)}</div>
                      <div className="text-sm text-text-secondary">{t(subtitle)}</div>
                    </div>
                    <div className="flex flex-col items-start gap-4 md:items-end">
                      <NotificationToggle
                        checked={!!notificationPreference.frequency}
                        disabled={!!loadingNotification}
                        loading={loadingNotification === `${notificationPreference.uuid}-uuid`}
                        notificationKey={notificationKey}
                        onCheckedChange={(checked) =>
                          updateNotificationPreference({
                            checked,
                            notificationKey,
                          })
                        }
                      />
                      {notificationKey === 'account.invoice.activity' && (
                        <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-3">
                          <Label htmlFor="invoiceAlertFrequency" className="shrink-0" size="sm">
                            {t('accountSettings.notificationSettingsInvoiceActivityNewAlertFrequency')}
                          </Label>
                          <Select
                            disabled={!notificationPreference.frequency || !!loadingNotification}
                            onValueChange={(value) =>
                              updateNotificationPreference({ frequency: value, notificationKey })
                            }
                            value={invoiceAlertFrequency}
                          >
                            <SelectTrigger id="invoiceAlertFrequency" className="w-52">
                              <SelectValue placeholder={t('accountSettings.notificationSettingsSelectFrequency')} />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="DAILY">
                                {t('accountSettings.notificationSettingsFrequencyDaily')}
                              </SelectItem>
                              <SelectItem value="WEEKLY">
                                {t('accountSettings.notificationSettingsFrequencyWeekly')}
                              </SelectItem>
                              <SelectItem value="BIWEEKLY">
                                {t('accountSettings.notificationSettingsFrequencyTwiceAWeek')}
                              </SelectItem>
                              <SelectItem value="MONTHLY">
                                {t('accountSettings.notificationSettingsFrequencyMonthly')}
                              </SelectItem>
                              <SelectItem value="QUARTERLY">
                                {t('accountSettings.notificationSettingsFrequencyQuarterly')}
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </CardForm.CardContent>
          }
        />
        {/* C2FO updates */}
        <CardForm.Card
          title={t('accountSettings.notificationSettingsC2FOUpdates')}
          subtitle={t('accountSettings.notificationSettingsC2FOUpdatesSubtitle')}
          content={
            <CardForm.CardContent className="space-y-6">
              {notificationGroups.c2foUpdates.map(({ title, subtitle, notificationKey }) => {
                const notificationPreference = getNotificationPreference(notificationKey, communicationPreferences);

                return (
                  <div
                    key={notificationKey}
                    className="flex flex-col justify-between gap-4 border-b pb-6 last:border-b-0 last:pb-0 md:flex-row md:items-center"
                  >
                    <div>
                      <div className="font-medium">{t(title)}</div>
                      <div className="text-sm text-text-secondary">{t(subtitle)}</div>
                    </div>
                    <div>
                      <NotificationToggle
                        checked={!!notificationPreference.frequency}
                        disabled={!!loadingNotification}
                        loading={loadingNotification === `${notificationPreference.uuid}-uuid`}
                        notificationKey={notificationKey}
                        onCheckedChange={(checked) =>
                          updateNotificationPreference({
                            checked,
                            notificationKey,
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </CardForm.CardContent>
          }
        />
        {/* unsubscribe */}
        <CardForm.Card
          content={
            <CardForm.CardContent className="flex flex-col justify-between gap-6 md:flex-row md:items-center">
              <div>{t('accountSettings.notificationSettingsUnsubscribeText')}</div>
              <div className="shrink-0">
                <Unsubscribe disabled={!!loadingNotification} />
              </div>
            </CardForm.CardContent>
          }
        />
      </div>
    </div>
  );
};

const NotificationSettings = () => (
  <QueryBoundaries
    LoadingComponent={() => (
      <div className="-mt-36">
        <FullPageLoader />
      </div>
    )}
  >
    <NotificationSettingsComponent />
  </QueryBoundaries>
);

export default NotificationSettings;
