import { ElementType } from 'react';
import { Chip, ChipProps, cn, IconButton, Tooltip } from '@c2fo/liquidity';
import { CustomerIcon } from './CustomerIcon';

export interface CustomerCardProps {
  customerName: string;
  customerLogoUrl?: string | null;
  additionalDetails?: {
    key: string;
    value: string | null | undefined;
  }[];
  chipLabelText?: string;
  chipTooltipText?: string;
  chipType?: ChipProps['type'];
  onClick?: () => void;
  isSelected?: boolean;
  iconButtonIcon?: ElementType;
}

export const CustomerCard = ({
  customerName,
  customerLogoUrl,
  additionalDetails,
  chipLabelText,
  chipTooltipText,
  chipType,
  onClick,
  isSelected,
  iconButtonIcon,
}: CustomerCardProps) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={cn(
        'relative flex h-[200px] w-full flex-col items-center justify-between overflow-hidden rounded-lg border-2 bg-white p-2 shadow-md transition-shadow',
        { 'border-primary-500': isSelected },
        { 'cursor-auto': !onClick },
        { 'hover:shadow-xl': !!onClick }
      )}
      onClick={() => onClick && onClick()}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick && onClick();
        }
      }}
    >
      <div className="flex h-full items-center justify-center">
        <div
          className={cn('scale-100 transition-transform', {
            'scale-125': isSelected,
          })}
        >
          <CustomerIcon buyerName={customerName} buyerLogo={customerLogoUrl} />
        </div>
      </div>

      <div className="flex w-full flex-col gap-1">
        <p className="m-0 w-full border-t p-0 pt-2 text-xs font-semibold text-text-secondary">{customerName}</p>

        {additionalDetails
          ?.filter((detail) => !!detail.value)
          .map((detail) => (
            <p key={detail.key} className="m-0 w-full p-0 text-xs font-normal text-text-secondary">
              {detail.value}
            </p>
          ))}
      </div>

      {chipLabelText && (
        <Chip
          variant="outlined"
          type={chipType}
          isRound
          size="xs"
          label={
            chipTooltipText ? (
              <Tooltip>
                <Tooltip.Trigger>{chipLabelText}</Tooltip.Trigger>
                <Tooltip.Content arrow variant="dark" className="max-w-[250px] text-wrap">
                  {chipTooltipText}
                </Tooltip.Content>
              </Tooltip>
            ) : (
              chipLabelText
            )
          }
          className="pointer-events-auto absolute left-3 top-3"
        />
      )}
      {iconButtonIcon && (
        <div className="absolute right-2 top-2">
          <IconButton icon={iconButtonIcon} size="sm" name="Edit" variant="ancillary" className="h-7 w-7 p-1" />
        </div>
      )}
    </div>
  );
};
