import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TextInput,
  useSnackbar,
} from '@c2fo/liquidity';
import { SUPPORTED_LOCALES } from '@/i18n';
import useUpdateLocation from '@/pages/AccountSettings/data/useUpdateLocation';
import useFormDropdownOptions from '@/pages/AccountSettings/utils/useFormDropdownOptions';

interface LocationFormInputs {
  country: string;
  useDefaultFormatting: boolean;
  dateDisplayFormat: string;
  locale: string;
  numberDisplayFormat: string;
  postalCode: string;
}

interface LocationFormProps {
  defaultValues: LocationFormInputs;
  onClose: () => void;
}

const userCustomDateFormats = ['yyyy-MMM-dd', 'dd MMM yyyy', 'MMM dd, yyyy'];
const numberFormatOptions = [
  {
    label: '1 234 567,89',
    value: 'fr-FR',
  },
  {
    label: '1,234,567.89',
    value: 'en-US',
  },
  {
    label: '1.234.567,89',
    value: 'es-ES',
  },
  {
    label: '12,34,567.89',
    value: 'en-IN',
  },
];

const LocationForm = ({ defaultValues, onClose }: LocationFormProps) => {
  const { i18n, t } = useTranslation();
  const showSnackbar = useSnackbar();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    resetField,
    watch,
  } = useForm<LocationFormInputs>({ defaultValues });
  const useDefaultFormatting = watch('useDefaultFormatting');
  const { mutateAsync, isLoading } = useUpdateLocation();
  const { countryOptions, localeOptions } = useFormDropdownOptions();

  const locale = SUPPORTED_LOCALES.find((l) => l.code === i18n.language);
  const dateFormatOptions = userCustomDateFormats.map((value) => ({
    label: locale ? format(new Date(), value, { locale: locale.dateFnsLocale }) : value,
    value,
  }));

  const onSubmit: SubmitHandler<LocationFormInputs> = async (data) => {
    const args = {
      address: {
        country: data.country,
        postalCode: data.postalCode,
      },
      locale: data.locale,
      formatPreferences: {
        dateDisplayFormat: data.dateDisplayFormat !== '' ? data.dateDisplayFormat : null,
        numberDisplayFormat: data.numberDisplayFormat !== '' ? data.numberDisplayFormat : null,
        useCustomFormatting: !data.useDefaultFormatting,
      },
    };

    await mutateAsync(args, {
      onError: () => {
        showSnackbar({ message: t('accountSettings.error') });
      },
      onSuccess: () => {
        showSnackbar({ message: t('accountSettings.success') });
        onClose();
      },
    });
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="country" required>
            {t('accountSettings.countryArea')}
          </Label>
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger aria-label={t('accountSettings.countryArea')} hasError={!!errors.country}>
                  <SelectValue placeholder={t('accountSettings.countryArea')} />
                </SelectTrigger>
                <SelectContent>
                  {countryOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="w-full space-y-1 md:w-1/4">
          <Label htmlFor="postalCode">{t('accountSettings.postalCode')}</Label>
          <TextInput
            id="postalCode"
            className="w-full"
            hasError={!!errors.postalCode}
            placeholder={t('accountSettings.postalCode')}
            {...register('postalCode')}
          />
        </div>
      </div>
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="locale" required>
            {t('accountSettings.languagePreference')}
          </Label>
          <Controller
            control={control}
            name="locale"
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger aria-label={t('accountSettings.languagePreference')} hasError={!!errors.locale}>
                  <SelectValue placeholder={t('accountSettings.languagePreference')} />
                </SelectTrigger>
                <SelectContent>
                  {localeOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <Checkbox
          id="useDefaultFormatting"
          color="primary"
          {...register('useDefaultFormatting', {
            // reset date and number display format when custom formatting is disabled
            onChange(event) {
              if (event.target.checked) {
                setValue('dateDisplayFormat', '');
                setValue('numberDisplayFormat', '');
              } else {
                resetField('dateDisplayFormat');
                resetField('numberDisplayFormat');
              }
            },
          })}
        />
        <Label htmlFor="useDefaultFormatting" className="cursor-pointer">
          {t('accountSettings.defaultNumberAndDateFormatting')}
        </Label>
      </div>
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="numberDisplayFormat" required={!useDefaultFormatting}>
            {t('accountSettings.numberDisplayFormat')}
          </Label>
          <Controller
            control={control}
            name="numberDisplayFormat"
            render={({ field }) => (
              <Select disabled={useDefaultFormatting} onValueChange={field.onChange} value={field.value}>
                <SelectTrigger
                  aria-label={t('accountSettings.numberDisplayFormat')}
                  hasError={!!errors.numberDisplayFormat}
                >
                  <SelectValue placeholder={t('accountSettings.numberDisplayFormat')} />
                </SelectTrigger>
                <SelectContent>
                  {numberFormatOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            rules={{ required: !useDefaultFormatting }}
          />
        </div>
        <div className="w-full space-y-1 md:w-1/2">
          <Label htmlFor="dateDisplayFormat" required={!useDefaultFormatting}>
            {t('accountSettings.dateDisplayFormat')}
          </Label>
          <Controller
            control={control}
            name="dateDisplayFormat"
            render={({ field }) => (
              <Select disabled={useDefaultFormatting} onValueChange={field.onChange} value={field.value}>
                <SelectTrigger
                  aria-label={t('accountSettings.dateDisplayFormat')}
                  hasError={!!errors.dateDisplayFormat}
                >
                  <SelectValue placeholder={t('accountSettings.dateDisplayFormat')} />
                </SelectTrigger>
                <SelectContent>
                  {dateFormatOptions.map(({ label, value }) => (
                    <SelectItem key={value} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            rules={{ required: !useDefaultFormatting }}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Button disabled={isLoading} onClick={onClose} variant="secondary">
          {t('core.cancel')}
        </Button>
        <Button loading={isLoading} type="submit">
          {t('core.save')}
        </Button>
      </div>
    </form>
  );
};

export default LocationForm;
