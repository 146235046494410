import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, cn, Modal, ModalActions, ModalContent, ModalTitleInfo, ModalTitleInverse } from '@c2fo/liquidity';
import circleCheck from '@/assets/circlecheck.svg?url';
import QueryBoundaries from '@/components/QueryBoundaries';
import useCreateRecurringRule from '@/data/useCreateRecurringRule';
import useExchangeRates from '@/data/useExchangeRates';
import { RecurringRuleCategory, RecurringRuleCriteria } from '@/data/useRecurringRules';
import { TakerMarket } from '@/data/useTakerMarkets';
import { TakerMarketGroupType } from '@/data/useTakerMarketsGroups';
import getTakerMarketName from '@/utils/getTakerMarketName';
import useRestrictions from '@/utils/useRestrictions';
import useSelectedCurrency from '@/utils/useSelectedCurrency';
import CategorySelect from './components/CategorySelect';
import CriteriaSelect from './components/CriteriaSelect';
import DivisionSelectTable from './components/DivisionSelectTable';
import ExistingRulesAlert from './components/ExistingRulesAlert';
import ReviewRule from './components/ReviewRule';
import defaultRuleValues from './utils/defaultRuleValues';
import { getInitialState, recurringRulesFormReducer } from './utils/recurringRulesFormReducer';
import useGetRecurringRulesForTakerMarkets from './utils/useGetRecurringRulesForTakerMarkets';
import useRuleFormContent from './utils/useRuleFormContent';
import useValidateRuleForm, { RecurringRuleFormError } from './utils/useValidateRuleForm';
import { mapFormCriteriaToRuleRecord, mapRuleRecordToSEASubmit } from './utils/utils';

export interface RecurringRulesFormProps {
  /* callback to close the modal */
  onClose: () => void;
  /* whether or not the modal is open */
  open: boolean;
  /* where the recurring rule form modal is opened from */
  referrer: 'divisionDetails' | 'homepage' | 'rulesManager';
  /* a list of taker markets to render within the division select table */
  takerMarkets: TakerMarket[];
  /* default values to pre-populate the form with */
  defaultValues?: Partial<RecurringRuleFormValues>;
  /*
   * whether or not the form should adapt config for a single division
   * will hide the division select table and display information
   * within the category select for a single division
   * defaults to false
   */
  isForSingleDivision?: boolean;
  /*
   * the mode of the form
   * create will display as creating a rule
   * edit will display as editing a rule
   * readOnly prevents create/edit and displays list of the rules
   * defaults to create
   */
  mode?: 'create' | 'edit' | 'readOnly';
  /* the type of taker market group */
  type?: TakerMarketGroupType;
  /* Set while api is calling to prevent closing modal while loading */
  setDisableModalClose: (value: boolean) => void;
}

export interface RecurringRuleFormCriteriaValue {
  type: RecurringRuleCriteria;
  value: string;
}

export interface RecurringRuleFormValues {
  category: RecurringRuleCategory;
  criteria: RecurringRuleFormCriteriaValue[];
  takerMarkets: TakerMarket[];
}

/**
 * initial form values default to days paid early
 * if a category is provided, the default values will be set to the category
 */
const getInitialFormValues = (defaultValues?: Partial<RecurringRuleFormValues>) => {
  return {
    defaultValues: {
      ...(defaultValues?.category ? defaultRuleValues[defaultValues?.category] : defaultRuleValues['dpe']),
      ...defaultValues,
    },
  };
};

const RecurringRulesFormComponent = ({
  defaultValues,
  isForSingleDivision = false,
  mode = 'create',
  onClose,
  referrer,
  takerMarkets,
  type,
  setDisableModalClose,
}: RecurringRulesFormProps) => {
  const { t } = useTranslation();
  const selectedCurrency = useSelectedCurrency();
  const { data: exchangeRates = {} } = useExchangeRates();
  const [errors, setErrors] = useState<RecurringRuleFormError[]>([]);
  const { getRuleFormContent } = useRuleFormContent();
  const [loading, setLoading] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<{
    showError: boolean;
    takerMarkets: { takerId: number; marketId: number }[];
  }>({ showError: false, takerMarkets: [] });
  const { mutateAsync: createRecurringRule } = useCreateRecurringRule();
  const initialState = getInitialState({ category: defaultValues?.category, mode });
  const [state, dispatch] = useReducer(recurringRulesFormReducer, initialState);
  const initialFormValues = getInitialFormValues(defaultValues);
  const methods = useForm<RecurringRuleFormValues>(initialFormValues);
  const category = methods.watch('category');
  const selectedTakerMarkets = methods.watch('takerMarkets');
  const { description } = getRuleFormContent(category);
  const { validateRuleForm } = useValidateRuleForm();
  const { getRecurringRulesForTakerMarkets } = useGetRecurringRulesForTakerMarkets();
  const { getRestrictions } = useRestrictions();
  const { canEditRecurringRules } = getRestrictions([]);
  const toCurrency = isForSingleDivision ? selectedTakerMarkets?.[0]?.currency ?? selectedCurrency : selectedCurrency;
  const isReadOnlyMode = mode === 'readOnly' || !canEditRecurringRules;

  const onTakerMarketTableSelect = (takerMarkets: TakerMarket[]) => {
    methods.setValue('takerMarkets', takerMarkets);
    setErrors(errors.filter((error) => error.type !== 'takerMarkets'));
  };

  const handleClose = () => {
    setSubmitErrors({ showError: false, takerMarkets: [] });
    onClose();
  };

  const handleCreateAnother = () => dispatch({ type: 'createAnother' });

  const onSubmit: SubmitHandler<RecurringRuleFormValues> = async (data) => {
    setLoading(true);
    setDisableModalClose(true);

    const ruleRecord = mapFormCriteriaToRuleRecord(data.criteria);
    const existingRules = getRecurringRulesForTakerMarkets(data.takerMarkets);
    const seaUpsertRuleBody = mapRuleRecordToSEASubmit(ruleRecord, data.takerMarkets, existingRules, toCurrency);

    // call sea api hook
    await createRecurringRule(seaUpsertRuleBody, {
      onSuccess: () => {
        setLoading(false);
        setDisableModalClose(false);
        setSubmitErrors({ showError: false, takerMarkets: [] });

        // send to create another if there are no errors
        dispatch({ type: 'setDisplay', payload: { display: 'success' } });
      },
      onError: () => {
        setLoading(false);
        setDisableModalClose(false);
        // We just show the error on the form, we don't need to show that there is error on specific taker markets
        setSubmitErrors({ showError: true, takerMarkets: [] });
      },
    });

    return;
  };

  const handleStepBack = () => {
    dispatch({ type: 'stepBack' });
    setErrors([]);
    setSubmitErrors({ showError: false, takerMarkets: [] });
  };

  const handleStepForward = () => {
    if (state.currentStep === 'criteria') {
      // validate the form before advancing
      const [criteria, takerMarkets] = methods.getValues(['criteria', 'takerMarkets']);
      const errors = validateRuleForm({ criteria, takerMarkets });

      if (errors.length > 0) {
        return setErrors(errors);
      }

      // if the form is valid, advance to the next step
      dispatch({ type: 'stepForward' });
    }
  };

  // set width of modal content based on current step and display
  const modalContentClassName = cn(
    'w-full min-w-full pt-3 sm:w-[568px]',
    { 'w-full sm:w-full lg:w-[704px]': state.currentStep === 'summary' },
    { 'w-full sm:w-full lg:w-[880px]': state.currentStep === 'criteria' && !isForSingleDivision },
    {
      'w-full sm:w-[592px] lg:w-[592px]': state.currentStep === 'category' || state.display === 'success',
    }
  );

  return (
    <>
      {state.currentStep === 'category' || state.display === 'success' ? (
        <ModalTitleInfo>
          {isReadOnlyMode || state.currentStep === 'category'
            ? t('recurringRules.title')
            : state.display === 'success'
            ? t(`recurringRules.ruleApplied`)
            : t('recurringRules.createRecurringRule')}
        </ModalTitleInfo>
      ) : (
        <ModalTitleInverse>
          <span className="flex flex-col">
            <span>
              {state.isEditMode ? t('recurringRules.editRecurringRule') : t('recurringRules.createRecurringRule')}
            </span>
            {isForSingleDivision && selectedTakerMarkets[0] && (
              <span className="text-sm font-normal text-text-secondary">
                {getTakerMarketName(selectedTakerMarkets[0])}
              </span>
            )}
          </span>
        </ModalTitleInverse>
      )}
      {isReadOnlyMode ? (
        <ModalContent className={modalContentClassName}>
          <CategorySelect
            advanceStep={() => null}
            setEditMode={() => null}
            isForSingleDivision={isForSingleDivision}
            readOnly
            takerMarkets={takerMarkets}
          />
        </ModalContent>
      ) : (
        <ModalContent key={state.currentStep} className={modalContentClassName}>
          {state.display === 'success' ? (
            <div className="flex h-full w-full flex-col items-center justify-center space-y-6 pt-4 text-center">
              <img src={circleCheck} alt="circle check" className="h-16 w-16" />
              <div>{t('recurringRules.success')}</div>
            </div>
          ) : (
            <FormProvider {...methods}>
              <form>
                {/* step 1 - category select */}
                {state.currentStep === 'category' ? (
                  <CategorySelect
                    advanceStep={() => dispatch({ type: 'stepForward' })}
                    isForSingleDivision={isForSingleDivision}
                    takerMarkets={takerMarkets}
                    setEditMode={(isEditMode) => dispatch({ type: 'setEditMode', payload: { isEditMode } })}
                  />
                ) : (
                  // additional steps show a rule title and description
                  <div className="space-y-6">
                    <div className="text-sm">{description}</div>
                    {/* step 2 - division select */}
                    {state.currentStep === 'criteria' && (
                      <>
                        <CriteriaSelect errors={errors} setErrors={setErrors} toCurrency={toCurrency} />
                        {!isForSingleDivision && (
                          <>
                            <ExistingRulesAlert takerMarkets={takerMarkets} />
                            <DivisionSelectTable
                              hasError={errors.find((error) => error.type === 'takerMarkets')}
                              onSelect={onTakerMarketTableSelect}
                              selectedTakerMarkets={selectedTakerMarkets}
                              takerMarkets={takerMarkets}
                              type={type}
                            />
                          </>
                        )}
                      </>
                    )}
                    {/* step 3 - summary */}
                    {state.currentStep === 'summary' && (
                      <ReviewRule
                        exchangeRates={exchangeRates}
                        toCurrency={toCurrency}
                        showError={submitErrors.showError}
                        errorTakerMarkets={submitErrors.takerMarkets}
                      />
                    )}
                  </div>
                )}
              </form>
            </FormProvider>
          )}
        </ModalContent>
      )}
      <ModalActions {...(state.display === 'success' && { className: 'bg-secondary-500' })}>
        {state.display === 'success' ? (
          <div className="flex w-full flex-col items-center text-center text-white">
            <div className="mb-6">{t(`recurringRules.createAdditional.${referrer}`)}</div>
            <Button className="mb-4 w-full max-w-96" inverse onClick={handleCreateAnother} variant="cta">
              {t('recurringRules.createAnotherRule')}
            </Button>
            <Button className="w-full max-w-96" inverse onClick={handleClose} variant="ancillary">
              {t(`recurringRules.returnTo.${referrer}`)}
            </Button>
          </div>
        ) : (
          <>
            {state.currentStep === 'category' ? (
              <Button onClick={handleClose} variant="secondary">
                {t('core.close')}
              </Button>
            ) : (
              <>
                {state.availableSteps.indexOf(state.currentStep) !== 0 && (
                  <Button onClick={handleStepBack} variant="secondary">
                    {t('core.back')}
                  </Button>
                )}
                {state.currentStep === 'criteria' && <Button onClick={handleStepForward}>{t('core.next')}</Button>}
                {state.currentStep === 'summary' && (
                  <Button onClick={methods.handleSubmit(onSubmit)} loading={loading} variant="cta">
                    {t('recurringRules.applyRule')}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </ModalActions>
    </>
  );
};

const RecurringRulesForm = (props: Omit<RecurringRulesFormProps, 'setDisableModalClose'>) => {
  const [disableModalClose, setDisableModalClose] = useState(false);

  return (
    <Modal
      className="min-w-[unset]"
      onClose={props.onClose}
      open={props.open}
      disableOutsideClickClose={disableModalClose}
    >
      <QueryBoundaries>
        <RecurringRulesFormComponent {...props} setDisableModalClose={setDisableModalClose} />
      </QueryBoundaries>
    </Modal>
  );
};

export default RecurringRulesForm;
